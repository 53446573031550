import "./App.css";
import LoginOtp from "./Components/Auth/Loginotp";
import RetrivalCode from "./Components/Auth/RetrivalCode.js";
import PatientInfo from "./Components/PatientInfo/PatientInfo.js";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Walletcard from "./Components/WalletCard.js";
import { BrowserRouter as Router } from "react-router-dom";
import { Switch, Route, Redirect } from "react-router-dom";
import Pdfview from "./Components/pdfViewer.js";
import LoginMode from "./Components/Auth/LoginMode";
import Reupload from "./Components/Reupload/Reupload";
import MissingDose from "./Components/MissingDose/missingdose";
import Form from "./Components/Demo/Form";

function App() {
  return (
    <>
      <div className="mainHolder">
        <Header />
        <Router>
          <Route path="/" exact component={PatientInfo} />
          <Route path="/login" exact component={LoginMode} />
          <Route path="/login-otp" exact component={LoginOtp} />
          <Route path="/retrievalcode" exact component={RetrivalCode} />
          <Route path="/pdf" exact component={Pdfview} />
          <Route path="/wallet-card" exact component={Walletcard} />
          <Route path="/reupload" exact component={Reupload} />
          <Route path="/missing-dose" exact component={MissingDose} />
          <Route path="/testing-verify" exact component={Form} />
        </Router>
      </div>
      <Footer />
    </>
  );
}

export default App;
