import * as ActionConstants from "../Constant/index.js";
import * as api from "../Service/API/index";
// Auth------------
// Login
export function SendNotification_Action(data, res) {
  api.SendNotification_API(data, (response) => {
    return res(response);
  });
}
export function SendOTP_Action(data, res) {
  api.SendOTP_API(data, (response) => {
    return res(response);
  });
}
// SendOTP
export function ValidateOTP_Action(data, res) {
  api.ValidateOTP_API(data, (response) => {
    return res(response);
  });
}
// Resend OTP
export function GetOTP_Action(data, res) {
  api.GetOTP_API(data, (response) => {
    return res(response);
  });
}
// Resend OTP
export function GetOrg_Action(data, res) {
  api.GetOrg_API(data, (response) => {
    return res(response);
  });
}
//
export function GetVaccination_Action(data, res) {
  api.GetVaccination_API(data, (response) => {
    return res(response);
  });
}
//
export function GetCounties_Action(data, res) {
  api.GetCounties_API(data, (response) => {
    return res(response);
  });
}
export function AddVacination_Action(data, res) {
  api.AddVacination_API(data, (response) => {
    return res(response);
  });
}

export function ReloadNew_Action(data, res) {
  api.ReuploadNew_API(data, (response) => {
    return res(response);
  });
}

export function ReloadExist_Action(data, res) {
  api.ReuploadExist_API(data, (response) => {
    return res(response);
  });
}

export function ReloadMissing_Action(data, res) {
  api.ReuploadMissing_API(data, (response) => {
    return res(response);
  });
}

export function MissingDose_Action(data, res) {
  api.MissingDose_API(data, (response) => {
    return res(response);
  });
}

export function GET_BVI_COUNTRYLIST(data, res) {
  api.GET_BVI_COUNTRYLIST(data, (response) => {
    return res(response);
  });
}

export function GET_BVI_STATELIST(data, res) {
  api.GET_BVI_STATELIST(data, (response) => {
    return res(response);
  });
}