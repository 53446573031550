import React from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  FormControl,
  Select,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import moment from "moment";
import { MenuItem } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";

const ThirdStepper = ({
  setshowdiv3,
  setshowdiv4,
  setcurrentStep,
  setloader,
}) => {
  //========================states=================================//

  const [testDate, settestDate] = React.useState(null);
  const [testType, settestType] = React.useState("");
  const [testResult, settestResult] = React.useState("");

  //======Vaccine Card Image State======//
  const [vaccineFile, setvaccineFile] = React.useState();
  const [vaccineFilesize, setvaccineFilesize] = React.useState(false);
  const [vaccineTypeError, setvaccineTypeError] = React.useState(false);
  const [VaccineimgShow, setVaccineimgshow] = React.useState("");
  const [vaccineError, setvaccineError] = React.useState(false);

  //====Error====//
  const [testTypeError, settestTypeError] = React.useState(false);
  const [testResultError, settestResultError] = React.useState(false);
  const [testDateError, settestDateError] = React.useState(false);
  const handleChangeDate = (date) => {
    settestDate(date);
  };

  const VaccineHandler = (event) => {
    setvaccineTypeError(false);
    setvaccineError(false);
    const fileTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
      "image/heif",
    ];
    if (
      event.target.files.length != 0 &&
      fileTypes.includes(event.target.files[0].type)
    ) {
      if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "image/jpeg" ||
          event.target.files[0].type === "image/png" ||
          event.target.files[0].type === "image/jpg")
      ) {
        setvaccineFile(event.target.files);
        setVaccineimgshow(
          event.target.files.length != 0
            ? URL.createObjectURL(event.target.files[0])
            : ""
        );
        setvaccineError(false);
      } else if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "application/pdf" ||
          event.target.files[0].type === "image/heif")
      ) {
        setvaccineFile(event.target.files);
        setVaccineimgshow("");
        setvaccineError(false);
      }
    } else {
      setVaccineimgshow("");
      setvaccineFile();
      setvaccineTypeError(true);
      setvaccineError(false);
    }

    const fileSize =
      event.target.files.length != 0
        ? event.target.files[0].size / 1024 / 1024
        : "";
    if (event.target.files.length != 0 && fileSize > 4) {
      setvaccineFilesize(true);
    } else {
      setvaccineFilesize(false);
    }
  };

  const next3 = () => {
    settestTypeError(false);
    settestResultError(false);
    settestDateError(false);

    if (
      vaccineFile === undefined ||
      vaccineFile === "" ||
      vaccineFile.length == 0
    ) {
      setvaccineError(true);
      setvaccineTypeError(false);
    } else if (vaccineFilesize == true) {
      return 0;
    } else if (testType === "") {
      settestTypeError(true);
    } else if (testResult === "") {
      settestResultError(true);
    } else if (testDate === "Invalid date" || testDate === null) {
      settestDateError(true);
    } else {
      console.log("=====API Call======");
      setloader(true);
      const formData = new FormData();
      formData.append("cardFile", vaccineFile[0]);
      let data = {
        testDate: moment(testDate).format("yyyy-MM-DD"),
        testType: testType,
        testResult: testResult,
      };
      console.log("data", data);

      setshowdiv3(false);
      setshowdiv4(true);
      setcurrentStep(3);
      window.scrollTo(0, 0);
      setloader(false);
    }
  };

  return (
    <>
      <div className="form_body">
        <Grid container className="mt">
          <Grid item xs={12} md={12} sm={12}>
            <Typography className="mb" variant="h6">
              Get your digital testing record
            </Typography>
            <div className="form_back">
              <div className="form_overlay">
                <Typography className="pt mx" variant="subtitle1">
                  General Information
                </Typography>
                <Grid container spacing={3} className="px py">
                  <Grid item xs={12} md={6} sm={12} className="mt">
                    <div className="vaccination-card">
                      {vaccineFile ? (
                        <div>
                          Click 'Next' to continue or upload a different image
                          <br />
                          <span style={{ fontSize: "14px" }}>
                            Please make sure your name and date of birth are
                            visible in the image
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="d-inline-flex">
                      <label
                        htmlFor="file-upload1"
                        className="custom-file-upload"
                      >
                        {vaccineFile ? (
                          vaccineFile.length > 0 ? (
                            vaccineFile[0].name
                          ) : (
                            <span className="d-flex">
                              <InsertPhotoIcon
                                style={{ width: "20px", marginRight: "5px" }}
                              />
                              Upload testing record
                            </span>
                          )
                        ) : (
                          <span style={{ width: "100%" }} className="d-flex">
                            <InsertPhotoIcon
                              style={{ width: "20px", marginRight: "5px" }}
                            />
                            Upload testing record
                          </span>
                        )}
                      </label>
                      <span className="img-label">
                        Supports png, jpeg, jpg, pdf, heif
                      </span>
                      <input
                        id="file-upload1"
                        accept=".png, .jpeg, .jpg, .pdf , .heif"
                        data-max-size="32154"
                        type="file"
                        name="licenseFile"
                        onChange={VaccineHandler}
                      />
                    </div>
                    {vaccineError ? (
                      <div className="errorText">
                        Please upload testing record
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {vaccineFilesize ? (
                      <div className="errorText">
                        File size should not exceed more than 4 mb
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {vaccineTypeError ? (
                      <div className="errorText">
                        Please upload image with required type
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    {vaccineFile &&
                    vaccineFile.length > 0 &&
                    vaccineFile[0].type != "application/pdf" &&
                    vaccineFile[0].type != "image/heif" &&
                    vaccineFile[0].type !== "" ? (
                      <div className="testing-preview-img">
                        <img
                          alt="VaccinationPreviewImg"
                          src={
                            vaccineFile
                              ? vaccineFile.length > 0
                                ? VaccineimgShow
                                : ""
                              : ""
                          }
                        ></img>
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item xs={12} md={6} sm={12} className="mt">
                    <FormLabel component="legend">Test Type *</FormLabel>
                    <FormControl
                      className="form-dropdown"
                      style={{ marginTop: "10px" }}
                      fullWidth
                      variant="standard"
                    >
                      <Select
                        name="testType"
                        value={testType}
                        onChange={(e) => settestType(e.target.value)}
                        required
                      >
                        <MenuItem value={" Covid-19: BinaxNow"}>
                          Covid-19: BinaxNow
                        </MenuItem>
                        <MenuItem value={"Covid-19: ID NOW"}>
                          Covid-19: ID NOW
                        </MenuItem>
                        <MenuItem value={"Innova Rapid Antigen Test"}>
                          Innova Rapid Antigen Test
                        </MenuItem>
                        <MenuItem value={"RT-PCR"}>RT-PCR</MenuItem>
                        <MenuItem value={"Insti HIV-1/HIV-2 Antibody Test"}>
                          Insti HIV-1/HIV-2 Antibody Test
                        </MenuItem>
                      </Select>
                    </FormControl>

                    {testTypeError ? (
                      <div className="errorText">
                        Please select the test type
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} sm={12} className="mt">
                    <FormLabel component="legend">Test Result *</FormLabel>
                    <RadioGroup
                      name="testResult"
                      value={testResult}
                      onChange={(e) => settestResult(e.target.value)}
                    >
                      <div className="test_result mt">
                        <FormControlLabel
                          className="content_radio_btn"
                          value="Positive"
                          control={<Radio className="mx" />}
                          label="Positive"
                        />
                        <FormControlLabel
                          className="content_radio_btn"
                          value="Negative"
                          control={<Radio className="mx" />}
                          label="Negative"
                        />
                        <FormControlLabel
                          className=" content_radio_btn"
                          value="Invalid"
                          control={<Radio className="mx" />}
                          label="Invalid"
                        />
                      </div>
                    </RadioGroup>
                    {testResultError ? (
                      <div className="errorText">
                        Please select the test result
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} sm={12} className="mt">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        style={{ width: "100%", marginTop: "-7px" }}
                        clearable
                        value={testDate}
                        onChange={(date) => handleChangeDate(date)}
                        format="MM/dd/yyyy"
                        label="Test date (MM/DD/YYYY)"
                        maxDate={new Date()}
                        required
                        autoOk
                      />
                    </MuiPickersUtilsProvider>

                    {testDateError ? (
                      <div className="errorText">
                        Please select the test date
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3} className="px py">
                  <Grid item xs={12} md={12} sm={12}>
                    <div className="d-flex">
                      <div>
                        <button className="verifier_btn" onClick={next3}>
                          Next
                        </button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ThirdStepper;
