// Auth
export const SEND_NOTIFICATION_URL = process.env.REACT_APP_API_URL + "public/send/notification";
export const GET_OTP_URL = process.env.REACT_APP_API_URL + "public/get/otp/to";
export const SENDOTP_URL = process.env.REACT_APP_API_URL + "public/send/otp";
export const VALIDATEOTP_URL = process.env.REACT_APP_API_URL + "public/validate/otp";
export const GET_ORG_URL = process.env.REACT_APP_API_URL + "public/get/organizations";
export const GET_VACCINATION_URL = process.env.REACT_APP_API_URL + "public/get/vaccination/configs";
export const GET_COUNTIES_URL = process.env.REACT_APP_API_URL + "public/get/state/counties";
export const ADD_VACINATION_URL = process.env.REACT_APP_API_URL + "public/add/vaccination/details";
export const REUPLOAD_NEW = process.env.REACT_APP_API_URL + "public/fileUpload/new";
export const REUPLOAD_EXIST = process.env.REACT_APP_API_URL + "public/fileUpload/exist";
export const REUPLOAD_MISSING = process.env.REACT_APP_API_URL + "public/fileUpload/missingDose";
export const MISSING_DOSE_URL = process.env.REACT_APP_API_URL + 'public/add/missing/dose';
export const GET_BVI_COUNTRYLIST = process.env.REACT_APP_API_URL + "bvi/country/list";
export const GET_BVI_STATELIST = process.env.REACT_APP_API_URL + "bvi/state/list";
export const VAX_DETAILS_UPDATE =
  process.env.REACT_APP_API_URL + "public/vax/details/submit";