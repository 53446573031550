import React, { useEffect, useRef, useState } from "react";
import {
  FormLabel,
  Grid,
  Typography,
  InputLabel,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Select,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";
// import Switch from "@material-ui/core/Switch";
import "./patientInfo.css";
import { useHistory } from "react-router";
import moment from "moment";
import "react-phone-input-2/lib/style.css";
import * as actions from "../../Action/index";
import * as API from "../../Service/API/index.js";
// import DeleteIcon from "@mui/icons-material/Delete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Stepper from "react-stepper-horizontal";
import axios from "axios";
import * as API_URL from "../../Service/API-URL/index.js";
import { SpaTwoTone } from "@material-ui/icons";
import Switch from "react-switch";

// Material UI Global Styles
const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  input: {
    color: "#00AFB9",
  },
  paper: {
    position: "absolute",

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "20px",
  },
}));

const PatientInfo = () => {
  const [yeararr, Setyeararr] = React.useState([]);
  const [datearr, Setdatearr] = React.useState([]);
  const [getqueryString, CheckqueryString] = React.useState("");
  const [countryList, setCountryList] = React.useState([]);
  const [stateSelect, setstateSelect] = React.useState([]);
  
  useEffect(() => {
    let sub_array = [];
    for (var i = moment(new Date()).format("yyyy"); i - 1909; i--) {
      sub_array.push(i);
    }
    Setyeararr(sub_array);

    let date_array = [];
    for (var i = 1; i <= 31; i++) {
      let number = i < 10 ? "0" + i : i;
      date_array.push(number);
    }
    Setdatearr(date_array);
    // -----------------------------------------------------------------------------
    actions.GetOrg_Action((res) => {
      var deletedItem = res.info.splice(34, 1);
      let addOthers = [
        {
          organizationName: "Other",
          labId: 32,
        },
      ];
      let getres = [...addOthers, ...res.info];
      setstateOrg(getres);
    });
    GetStateList();
    CheckqueryString(
      new URLSearchParams(window.location.search).get("type") == "internal"
    );
  }, []);

  const history = useHistory();
  const classes = useStyles();
  const [vaccinetype, setvaccinetype] = React.useState("");
  const [dosecountList, setdoseCountList] = React.useState(["First Dose", "Second Dose", "Third Dose", "Fourth Dose", "Fifth Dose", "Sixth Dose", "Seventh Dose", "Eighth Dose", "Ninth Dose", "Tenth Dose"])
  const [doseList, setdoseList] = React.useState([
    {
      vaccineDate: null,
      first_notsure: false,
      lotNumber: "",
      doseCounty: null,
      country: "US",
      state: "VI,Virgin Islands",
      labId: null,
      location: "",
      otherLabName: "",
      doseLoc:"",
      stateList: stateSelect,
    },
  ]);

    //======Error States======//
    const [vaccinationError, setvaccinationError] = React.useState(false);
    const [vaccinationdateError, setvaccinationdateError] = React.useState(false);
    const [lotError, setlotError] = React.useState(false);
    const [countryError, setcountryError] = React.useState(false);
    const [stateError, setstateError] = React.useState(false);
    const [locationError, setlocationError] = React.useState(false);
    const [OtherlocationError, setOtherlocationError] = React.useState(false);

    const [secvaccinationdateError, setsecvaccinationdateError] = React.useState(false);
    const [seclotError, setseclotError] = React.useState(false);
    const [seccountryError, setseccountryError] = React.useState(false);
    const [secstateError, setsecstateError] = React.useState(false);
    const [seclocationError, setseclocationError] = React.useState(false);
    const [secOtherlocationError, setsecOtherlocationError] = React.useState(false);

  const [Success, SetSuccess] = React.useState(false);
  const [ApiError, SetApiError] = React.useState(false);
  const [loader, setloader] = React.useState(false);
  const [fnameError, setfnameError] = React.useState(true);
  const [lnameError, setlnameError] = React.useState(true);
  const [mobileError, setmobileError] = React.useState(true);
  const [emailError, setemailError] = React.useState(true);
  const [ValidemailError, setValidemailError] = React.useState(true);
  const [dobdateError, setdobdateError] = React.useState(true);
  const [dobmonthError, setdobmonthError] = React.useState(true);
  const [dobyearError, setdobyearError] = React.useState(true);
  const [dobError, setdobError] = React.useState(true);
  const [vacclabError, setvacclabError] = React.useState(true);
  const [vaccdateError, setvaccdateError] = React.useState(true);
  const [drivingError, setdrivingError] = React.useState(true);
  const [vaccineError, setvaccineError] = React.useState(true);

  const [vaccinenameError, setvaccineNameError] = React.useState(true);
  const [receivedError, setreceivedError] = React.useState(true);
  const [firstdoseDateError, setfirstdoseDateError] = React.useState(true);
  const [firstdoseCountryError, setfirstdoseCountryError] = React.useState(true);
  const [firstdoseStateError, setfirstdoseStateError] = React.useState(true);
  const [firstdosemonthError, setfirstdosemonthError] = React.useState(true);
  const [firstdoseyearError, setfirstdoseyearError] = React.useState(true);
  const [firstdoselocError, setfirstdoselocError] = React.useState(true);
  const [firstdoselotnoError, setfirstdoselotnoError] = React.useState(true);
  const [seconddosedateError, setseconddosedateError] = React.useState(true);
  const [seconddosemonthError, setseconddosemonthError] = React.useState(true);
  const [seconddoseyearError, setseconddoseyearError] = React.useState(true);
  const [seconddoselocError, setseconddoselocError] = React.useState(true);
  const [seconddoseCountryError, setseconddoseCountryError] = React.useState(true);
  const [seconddoseStateError, setseconddoseStateError] = React.useState(true);
  const [seconddoselotnoError, setseconddoselotnoError] = React.useState(true);
  const [agelimit, setAgelimit] = React.useState(false);
  const [stateOrg, setstateOrg] = React.useState([]);
  const [dobdate, setdobdate] = React.useState("");
  const [dobmonth, setdobmonth] = React.useState("");
  const [dobyear, setdobyear] = React.useState("");

  const [showdiv1, setshowdiv1] = React.useState(true);
  const [showdiv2, setshowdiv2] = React.useState(false);
  const [showdiv3, setshowdiv3] = React.useState(false);
  const [showdiv4, setshowdiv4] = React.useState(false);

  const handlevaccinetype = (event) => {
    setvaccinetype(event.target.value);
    if (event.target.value === "Janssen COVID-19 Vaccine") {
      setCovid19vaccine("");
    }
  };

  const doseOnchange = (e, index, field) => {
    if (field === "vaccineDate") {
      const list = [...doseList];
      list[index]["vaccineDate"] = e === null ? null : moment(e);
      setdoseList(list);
    } else if (
      field === "state" ||
      field === "vaccineName") {
      const { name, value } = e.target;
      const list = [...doseList];
      list[index][name] = value;
      setdoseList(list);
    } else if (field === "lotNumber") {
      const name = e.target.name;      
      const value = e.target.value.replaceAll('"', "'");
      const list = [...doseList];
      list[index][name] = value.replaceAll("''", "'");
      setdoseList(list);
    } else if (field === "country") {
      const { name, value } = e.target;
      const list = [...doseList];
      list[index][name] = value;
      setdoseList(list);
      setloader(true);
      countryList.map((item) => {
        if (item.countryCode == value) {
          let data = {
            countryId: item.id,
          };
          API.GET_BVI_STATELIST(data, (res) => {
            if (res.statusCode === 200) {
              list[index]["state"] = "";
              let allEntries = Object.entries(res.info.states);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              list[index]["stateList"] = sortedEntries;
              setloader(false);
            }
          });
        }
      });
    } else if (field === "location") {
      const { name, value } = e.target;
      let labidvalue = value.split("+")[1];
      let labname = value.split("+")[0];
      const lablocation = stateOrg.filter((item) => {
        return item.labId == value;
      });
      const list = [...doseList];
      list[index]["labId"] = labidvalue;
      list[index]["location"] = labname;
      list[index]["doseLoc"] = e.target.value;
      setdoseList(list);
    } else if (
      field === "first_notsure" &&
      (e.target.checked === false || e.target.checked === true)
    ) {
      const { name, checked } = e.target;
      const list = [...doseList];
      list[index][name] = checked;
      setdoseList(list);
    } else if (field === "otherLabName") {
      const name = e.target.name;
      const value = e.target.value.replaceAll('"', "'");
      const list = [...doseList];
      list[index][name] = value.replaceAll("''", "'");
      setdoseList(list);
    }
  };

  const handleRemoveClick = (index) => {
    const list = [...doseList];
    list.splice(index, 1);
    setdoseList(list);
  };

  const [sec_notsure, setsec_notsure] = React.useState(false);
  const handlesec_sure = (event) => {
    if (event.target.checked === true) {
      setformState({
        secondlotno: "I'm not sure",
      });
    } else {
      setformState({
        secondlotno: "",
      });
    }
    setsec_notsure(event.target.checked);
  };

  const [drivingFile, setdrivingFile] = React.useState();
  const [drivingFilesize, setdrivingFilesize] = React.useState(false);
  const [drivingTypeError, setdrivingTypeError] = React.useState(false);
  const [vaccineFile, setvaccineFile] = React.useState();
  const [vaccineFilesize, setvaccineFilesize] = React.useState(false);
  const [vaccineTypeError, setvaccineTypeError] = React.useState(false);
  const [drivingimgShow, setdrivingimgshow] = React.useState("");
  const DrivingHandler = (event) => {
    const fileTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
      "image/heif",
    ];
    if (
      event.target.files.length != 0 &&
      fileTypes.includes(event.target.files[0].type)
    ) {
      if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "image/jpeg" ||
          event.target.files[0].type === "image/png" ||
          event.target.files[0].type === "image/jpg")
      ) {
        setdrivingFile(event.target.files);
        setdrivingimgshow(
          event.target.files.length != 0
            ? URL.createObjectURL(event.target.files[0])
            : ""
        );
        setdrivingTypeError(false);
        setdrivingError(true);
      } else if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "application/pdf" ||
          event.target.files[0].type === "image/heif")
      ) {
        setdrivingFile(event.target.files);
        setdrivingimgshow("");
        setdrivingTypeError(false);
        setdrivingError(true);
      }
    } else {
      setdrivingimgshow("");
      setdrivingFile();
      setdrivingTypeError(true);
    }

    const fileSize =
      event.target.files.length != 0
        ? event.target.files[0].size / 1024 / 1024
        : "";
    if (event.target.files.length != 0 && fileSize > 4) {
      setdrivingFilesize(true);
    } else {
      setdrivingFilesize(false);
    }
  };
  const [VaccineimgShow, setVaccineimgshow] = React.useState("");

  const VaccineHandler = (event) => {
    const fileTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
      "image/heif",
    ];
    if (
      event.target.files.length != 0 &&
      fileTypes.includes(event.target.files[0].type)
    ) {
      if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "image/jpeg" ||
          event.target.files[0].type === "image/png" ||
          event.target.files[0].type === "image/jpg")
      ) {
        setvaccineFile(event.target.files);
        setVaccineimgshow(
          event.target.files.length != 0
            ? URL.createObjectURL(event.target.files[0])
            : ""
        );

        setvaccineTypeError(false);
        setvaccineError(true);
      } else if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "application/pdf" ||
          event.target.files[0].type === "image/heif")
      ) {
        setvaccineFile(event.target.files);
        setVaccineimgshow("");
        setvaccineTypeError(false);
        setvaccineError(true);
      }
    } else {
      setVaccineimgshow("");
      setvaccineFile();
      setvaccineTypeError(true);
    }

    const fileSize =
      event.target.files.length != 0
        ? event.target.files[0].size / 1024 / 1024
        : "";
    if (event.target.files.length != 0 && fileSize > 4) {
      setvaccineFilesize(true);
    } else {
      setvaccineFilesize(false);
    }
  };

  const [vaccineLoc, setvaccineLoc] = React.useState("");
  const handleLocation = (e) => {
    setvaccineLoc(e.target.value);
    let a = e.target.value.split(",");
    localStorage.setItem("labId", a[1]);
  };
  //========Dose Location  Specify Functionalities===========//
  const [firstdoseLoc, setfirstdoseLoc] = React.useState("");
  const [seconddoseLoc, setseconddoseLoc] = React.useState("");
  const [firstDoseError, setfirstDoseError] = React.useState(true);
  const [secondDoseError, setsecondDoseError] = React.useState(true);
  //====On Change function ====//
  const handlefirstdoseLoc = (e) => {
    setfirstdoseLoc(e.target.value);
  };
  const handleseconddoseLoc = (e) => {
    setseconddoseLoc(e.target.value);
  };

  //===================//
  const initialFormData = {
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    selectdate: "",
    selectmonth: "",
    selectyear: "",
  };

  const [secondLoc, setsecondLoc] = React.useState("");
  const [firstLoc, setfirstLoc] = React.useState("");
  const [newnumber, setNumber] = React.useState("");
  const [dialCode, setdialCode] = React.useState("");

  const mobileNumberChange = (number,e) => {
    setdialCode(e.dialCode);
    setNumber(number);
  };

  const [selectDateVaccine, setselectDateVaccine] = React.useState(null);

  const handleDateChange = (date) => {
    setselectDateVaccine(date);
  };

  const [formstate, setformState] = React.useState(initialFormData);
  const [errorform, seterrorform] = React.useState(false);
  const handleChangeForm = (event) => {
    const name = event.target.name;
    let value = event.target.value.replaceAll('"', "'");
    setformState({
      ...formstate,
      [name]: value.replaceAll("''", "'"),
    });
  };

  const [labID, setlabID] = React.useState("");
  const handleChangefirstLoc = (event) => {
    setfirstLoc(event.target.value);
  };
  const handleChangesecondLoc = (event) => {
    setsecondLoc(event.target.value);
  };
  // Toogle switch
  const [getcheckedA, setcheckedA] = React.useState(true);

  const handleChangeText = (event) => {
    setcheckedA(!getcheckedA);
  };
  //

  const EnterKeynext = (evt) => {
    if (evt.keyCode == 13) {
      ContactFormsubmit();
    }
  };

  const [Covid19vaccine, setCovid19vaccine] = React.useState("");
  const handleCovid19vaccine = (event) => {
    setCovid19vaccine(event.target.value);
    if(event.target.value === "Y"){
      setdoseList([
        ...doseList,
        {
          vaccineName: "",
          vaccineDate: null,
          first_notsure: false,
          lotNumber: "",
          country: "US",
          state: "VI,Virgin Islands",
          doseCounty: null,
          labId: null,
          location: "",
          otherLabName: "",
          doseLoc:"",
          stateList: stateSelect,
        },
      ]);
    } else {
      const list = [...doseList];
      list.splice(1, 1);
      setdoseList(list);
    }
  };

  const ContactFormsubmit = () => {
    if (
      formstate.firstName.trim() == "" &&
      formstate.firstName.trim().length == 0
    ) {
      setfnameError(false);
    } else if (
      formstate.lastName.trim() == "" &&
      formstate.lastName.trim().length == 0
    ) {
      setlnameError(false);
      setfnameError(true);
    } else if (newnumber == "" && newnumber.length == 0) {
      setmobileError(false);
      setlnameError(true);
      setfnameError(true);
    } else if (
      formstate.email.trim() == "" &&
      formstate.email.trim().length == 0
    ) {
      setemailError(false);
      setmobileError(true);
      setlnameError(true);
      setfnameError(true);
      return 0;
    } else if (
      !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
        formstate.email.trim()
      ) &&
      formstate.email.trim() != ""
    ) {
      setValidemailError(false);
      setemailError(true);
      setmobileError(true);
      setlnameError(true);
      setfnameError(true);
    } else if (dobmonth == "") {
      setdobmonthError(false);
      setdobdateError(true);
      setValidemailError(true);
      setemailError(true);
      setmobileError(true);
      setlnameError(true);
      setfnameError(true);
      return 0;
    } else if (dobdate == "") {
      setdobmonthError(true);
      setdobdateError(false);
      setValidemailError(true);
      setemailError(true);
      setmobileError(true);
      setlnameError(true);
      setfnameError(true);
      return 0;
    } else if (dobyear == "") {
      setdobyearError(false);
      setdobmonthError(true);
      setdobdateError(true);
      setValidemailError(true);
      setemailError(true);
      setmobileError(true);
      setlnameError(true);
      setfnameError(true);
      return 0;
    } else if (dobdate != "" && dobmonth != "" && dobyear != "") {
      var eighteenYearsAgo = moment().subtract(13, "years");
      var birthday = dobyear + "-" + dobmonth + "-" + dobdate;
      if (!eighteenYearsAgo.isAfter(birthday) && getqueryString === false) {
        setAgelimit(true);
        setdobyearError(true);
        setdobmonthError(true);
        setdobdateError(true);
      } else {
        setAgelimit(false);
        setdobyearError(true);
        setdobmonthError(true);
        setdobdateError(true);
        setValidemailError(true);
        setemailError(true);
        setmobileError(true);
        setlnameError(true);
        setfnameError(true);
        setcurrentStep(1);
        setshowdiv2(true);
        setshowdiv1(false);
        setshowdiv4(false);
      }
    } else {
      setAgelimit(false);
      setdobyearError(true);
      setdobmonthError(true);
      setdobdateError(true);
      setdobdateError(true);
      setValidemailError(true);
      setemailError(true);
      setmobileError(true);
      setlnameError(true);
      setfnameError(true);
      setcurrentStep(1);
      setshowdiv2(true);
      setshowdiv1(false);
      setshowdiv4(false);
    }
  };

  const VerifiyVaccination = (e) => {
    if (vaccinetype === "") {
      setvaccineNameError(false);
    } else if (
      vaccineFile === undefined ||
      vaccineFile === "" ||
      vaccineFile.length == 0
    ) {
      setvaccineNameError(true);
      setvaccineError(false);
      setvaccineTypeError(false);
    } else if (vaccineFilesize == true) {
      return 0;
    } else if (
      vaccinetype != "" &&
      vaccinetype != "Janssen COVID-19 Vaccine" &&
      Covid19vaccine == ""
    ) {
      setvaccineNameError(true);
      setvaccineError(true);
      setreceivedError(false);
    } else {
      //console.log("doseList",doseList);
      setreceivedError(true);
      let arr = doseList.map((item) => {
        return {
          vaccinationType: vaccinetype,
          doseDate:
            item.vaccineDate == null
              ? null
              : moment(item.vaccineDate).format("yyyy-MM-DD"),
          doseLot:
            item.first_notsure === true
              ? "I'm not sure"
              : item.lotNumber.trim(),
          doseCounty: item.doseCounty,
          doseCountry: item.country,
          doseState: item.state,
          doseLocation: item.location,
          doseLabId: parseInt(item.labId),
          doseOtherLabName: item.labId == 32 ? item.otherLabName.trim() : "",
        };
      });
      let filter_data = arr.filter(function (x) {
        if (x.vaccinationType != "") {
          setvaccinationError(false);
          return true;
        } else if (x.doseDate != "Invalid date" || x.doseDate != null) {
          setvaccinationdateError(false);
          setsecvaccinationdateError(false);
          return true;
        } else if (x.doseLot.trim() != "") {
          setlotError(false);
          setseclotError(false);
          return true;
        } else if (x.doseCountry != "") {
          setcountryError(false);
          return true;
        } else if (x.doseState != "") {
          setstateError(false);
          return true;
        } else if (x.doseLocation != "") {
          setlocationError(false);
          setseclocationError(false);
          return true;
        } else if (x.doseLabId !== 32 && x.doseOtherLabName.trim() !== "") {
          setOtherlocationError(false);
          setsecOtherlocationError(false);
          return true;
        }
      });

      let arrIndex = 0;
      let Checkfilter_data = arr.filter(function (x) {
        if (x.vaccinationType == "") {
          setvaccinationError(true);
          return true;
        } else if (x.doseDate === "Invalid date" || x.doseDate === null) {
          if(arrIndex == 0){
            setvaccinationdateError(true);
            setsecvaccinationdateError(false);
          }
          else if(arrIndex == 1){
            setvaccinationdateError(false);
            setOtherlocationError(false);
            setlocationError(false);
            setsecvaccinationdateError(true);
          }
          return true;
        } else if (x.doseLot.trim() == "") {
          if(arrIndex == 0){
            setlotError(true);
            setseclotError(false);
            setvaccinationdateError(false);
            setsecvaccinationdateError(false);
          }
          else if(arrIndex == 1){
            setlotError(false);
            setseclotError(true);
            setvaccinationdateError(false);
            setsecvaccinationdateError(false);
          }
          return true;
        } else if (x.doseCountry == "") {
          setcountryError(true);
          return true;
        } else if (x.doseState == "") {
          setstateError(true);
          return true;
        } else if (x.doseLocation == "") {
          if(arrIndex == 0){
            setlocationError(true);
            setseclocationError(false);
            setvaccinationdateError(false);
            setsecvaccinationdateError(false);
          }
          else if(arrIndex == 1){
            setlocationError(false);
            setseclocationError(true);
            setvaccinationdateError(false);
            setsecvaccinationdateError(false);
          }
          return true;
        } else if (x.doseLabId === 32 && x.doseOtherLabName.trim() === "") {
          if(arrIndex == 0){
            setOtherlocationError(true);
            setsecOtherlocationError(false);
            setvaccinationdateError(false);
            setsecvaccinationdateError(false);
            setlocationError(false);
            setseclocationError(false);
          }
          else if(arrIndex == 1){
            setOtherlocationError(false);
            setsecOtherlocationError(true);
            setvaccinationdateError(false);
            setsecvaccinationdateError(false);
            setlocationError(false);
            setseclocationError(false);
          }
          return true;
        }
        arrIndex += 1;
      });

      if (doseList.length) {
        for (let i = 0; i < doseList.length; i++) {
          if (doseList[i].vaccineName === "") {
            setvaccinationError(true);
          } else if (
            doseList[i].vaccineDate === "Invalid date" ||
            doseList[i].vaccineDate === null
          ) {
            if(i == 0){
              setvaccinationdateError(true);
              setsecvaccinationdateError(false);
            }
            else if(i == 1){
              setvaccinationdateError(false);
              setsecvaccinationdateError(true);
            }
          } else if (
            doseList[i].first_notsure === false &&
            doseList[i].lotNumber.trim() === ""
          ) {
            if(i == 0){
              setlotError(true);
              setseclotError(false);
              setvaccinationdateError(false);
              setsecvaccinationdateError(false);
            }
            else if(i == 1){
              setlotError(false);
              setseclotError(true);
              setvaccinationdateError(false);
              setsecvaccinationdateError(false);
            }
          } else if (doseList[i].country === "") {
            setcountryError(true);
          } else if (doseList[i].state === "") {
            setstateError(true);
          } else if (doseList[i].doseLocation === "") {
            if(i == 0){
              setlocationError(true);
              setseclocationError(false);
              setvaccinationdateError(false);
              setsecvaccinationdateError(false);
            }
            else if(i == 1){
              setlocationError(false);
              setseclocationError(true);
              setvaccinationdateError(false);
              setsecvaccinationdateError(false);
            }
          } else if (
            doseList[i].labId === 32 &&
            doseList[i].otherLabName.trim() === ""
          ) {
            if(i == 0){
              setOtherlocationError(true);
              setsecOtherlocationError(false);
              setvaccinationdateError(false);
              setsecvaccinationdateError(false);
            }
            else if(i == 1){
              setOtherlocationError(false);
              setsecOtherlocationError(true);
              setvaccinationdateError(false);
              setsecvaccinationdateError(false);
            }
          }
        }

        if (Checkfilter_data.length === 0) {
          console.log(">>>>>>> API call >>>>>>>>>>");
          setloader(true);
          const formData = new FormData();
          
          let data = {
            firstName: formstate.firstName.trim(),
            lastName: formstate.lastName.trim(),
            middleName: formstate.middleName.trim(),
            dob: `${dobyear}-${dobmonth}-${dobdate}`,
            email: formstate.email.trim(),
            mobileNumber: "+" + newnumber,
            sendNotificationToMobile: true,
            phoneCode: dialCode,
            doses: filter_data,
            submittedFrom: "Public",
          };

          formData.append("formData", JSON.stringify(data));
          formData.append("licenceFile", drivingFile[0]);
          formData.append("cardFile", vaccineFile[0]);
          // console.log("data", data)

          API.VAX_DETAILS_UPDATE(formData, (res) => {
            if (res.statusCode === 200) {
              console.log("success", res);
              setloader(false);
              setshowdiv3(false);
              setshowdiv4(true);
              setcurrentStep(2);
              window.scrollTo(0, 0);
              setdoseList([
                {
                  vaccineName: "",
                  vaccineDate: null,
                  first_notsure: false,
                  lotNumber: "",
                  country: "US",
                  state: "VI,Virgin Islands",
                  doseCounty: null,
                  labId: null,
                  location: "",
                  otherLabName: "",
                  doseLoc:"",
                  stateList: stateSelect,
                },
              ]);
              setvaccineFile("");
              setdrivingFile("");
            } else {
              setloader(false);
            }
          });
        }
      }
    }
  };
  const Verifiysubmit = (e) => {
    // if (vaccineLoc === "") {
    //   setvacclabError(false);
    // } else if (selectDateVaccine === null) {
    //   setvacclabError(true);
    //   setvaccdateError(false);
    // } else
    if (
      drivingFile === undefined ||
      drivingFile === "" ||
      drivingFile.length == 0
    ) {
      setvacclabError(true);
      setvaccdateError(true);
      setdrivingError(false);
      setdrivingTypeError(false);
    } else if (drivingFilesize == true) {
      return 0;
    } else {
      setloader(true);
      setvacclabError(true);
      setvaccdateError(true);
      setdrivingError(true);

      getvaccinationconfigs();
      GetCountryList();
      // GetStateList();
      // getcounties();           
      setcurrentStep(1);
      setshowdiv3(true);
      setshowdiv1(false);
      setshowdiv2(false);
      setloader(false);
      setdoseList([
        {
          vaccineName: "",
          vaccineDate: null,
          first_notsure: false,
          lotNumber: "",
          country: "US",
          state: "VI,Virgin Islands",
          doseCounty: null,
          labId: null,
          location: "",
          otherLabName: "",
          doseLoc:"",
          stateList: stateSelect,
        },
      ]);

/*       const formData = new FormData();
      // let vaccArr = vaccineLoc.split(",");
      let data = {
        firstName: formstate.firstName.trim(),
        middleName: formstate.middleName.trim(),
        lastName: formstate.lastName.trim(),
        dob: dobyear + "-" + dobmonth + "-" + dobdate,
        email: formstate.email.trim(),
        mobileNumber: "+" + newnumber,
        sendNotificationToMobile: getcheckedA,
      };
      formData.append("formData", JSON.stringify(data));
      formData.append("licenseFile", drivingFile[0]);

      actions.SendNotification_Action(formData, (res) => {
        if (res.statusCode == "200" && res.status) {
          if (res.info.matchingRecordFound == true && res.info.formId == 0) {
            setcurrentStep(2);
            setshowdiv4(true);
            setshowdiv1(false);
            setshowdiv2(false);
            // Empty form
            setformState({
              firstName: "",
              lastName: "",
              middleName: "",
              email: "",
              selectdate: "",
              selectmonth: "",
              selectyear: "",
              firstlotno: "",
              secondlotno: "",
              setvaccineLoc: "",
              setselectDateVaccine: null,
              drivingFile: "",
              setvaccinetype: "",
              vaccineFile: "",
              setCovid19vaccine: "",
              setfirstLoc: "",
              setsecondLoc: "",
              setfirst_notsure: false,
              setsec_notsure: false,
            });
            setNumber("");
            setfirstdoseLoc("");
            setseconddoseLoc("");
            //
            setloader(false);
            SetSuccess(true);
          } else if (res.info.matchingRecordFound == false && res.info.formId) {
            localStorage.setItem("formId", res.info.formId);
            getvaccinationconfigs();
            GetCountryList();
            GetStateList();
            // getcounties();           
            setcurrentStep(1);
            setshowdiv3(true);
            setshowdiv1(false);
            setshowdiv2(false);
            // Empty form
            setformState({
              firstName: "",
              lastName: "",
              middleName: "",
              email: "",
              selectdate: "",
              selectmonth: "",
              selectyear: "",
              firstlotno: "",
              secondlotno: "",
              setvaccineLoc: "",
              setselectDateVaccine: null,
              drivingFile: "",
              setvaccinetype: "",
              vaccineFile: "",
              setCovid19vaccine: "",
              setfirstLoc: "",
              setsecondLoc: "",
              setfirst_notsure: false,
              setsec_notsure: false,
            });
            setNumber("");
            setfirstdoseLoc("");
            setseconddoseLoc("");
            //
            setloader(false);
          }
        } else if (res.errorCode == "400" && res.errorMessage) {
          setloader(false);
          SetApiError(true);
        }
      }); */
    }
  };
  //
  const Backbtn = () => {
    setsteps([
      {
        title: "Get Started",
      },
      {
        title: "Verify Identity",
      },
      {
        title: "Submit Request",
      },
    ]);
    localStorage.clear();
    setAgelimit(false);
    setcurrentStep(0);
    setshowdiv1(true);
    setshowdiv2(false);
    setshowdiv4(false);

    setformState({
      firstName: "",
      lastName: "",
      middleName: "",
      email: "",
      selectdate: "",
      selectmonth: "",
      selectyear: "",
    });
    setdobdate("");
    setdobmonth("");
    setdobyear("");
    setNumber("");
    // 2nd div
    setvaccineLoc("");
    setselectDateVaccine(null);
    setdrivingFile();

    //

    setvaccinetype("");
    setvaccineFile("");
    setCovid19vaccine("");
    setdoseList([
      {
        vaccineName: "",
        vaccineDate: null,
        first_notsure: false,
        lotNumber: "",
        country: "US",
        state: "VI,Virgin Islands",
        doseCounty: null,
        labId: null,
        location: "",
        otherLabName: "",
        doseLoc:"",
        stateList:stateSelect,
      },
    ]);
    setlotError(false);
    setseclotError(false);
    setlocationError(false);
    setseclocationError(false);
    setOtherlocationError(false);
    setsecOtherlocationError(false);

  };
  //
  const [firstDose_country, setfirstDose_country] = React.useState("US");
  const [secondDose_country, setsecondDose_country] = React.useState("US");
  const [VaccineName, setVaccineName] = React.useState([]);
  const [getState, setstates] = React.useState("VI,Virgin Islands");
  const [sec_State, setSec_states] = React.useState("VI,Virgin Islands");
  const [Second_stateSelect, setSecond_stateSelect] = React.useState([]);

  const getvaccinationconfigs = () => {
    actions.GetVaccination_Action((res) => {
      setVaccineName(res.info);
    });
  };

  //======Get CountryList======//
  const GetCountryList = () => {
    actions.GET_BVI_COUNTRYLIST((res) => {
      setCountryList(res.info);
    });
  };

  const GetStateList = () => {
    let data = {
      countryId: 233,
    };
    actions.GET_BVI_STATELIST(data, (res) => {
      if (res.statusCode === 200) {
        let allEntries = Object.entries(res.info.states);
        let sortedEntries = allEntries.sort((a, b) =>
          a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
        );
        setstateSelect(sortedEntries);
        setSecond_stateSelect(sortedEntries);
        // setLoader(false);
        setdoseList(
          [
            {
              vaccineDate: null,
              first_notsure: false,
              lotNumber: "",
              doseCounty: null,
              country: "US",
              state: "VI,Virgin Islands",
              labId: null,
              location: "",
              otherLabName: "",
              doseLoc:"",
              stateList: stateSelect,
            },
          ]
        )
      }
    });
  }




  // Load county based on state
  const handleChangeState = (event) => {
    let check1 = event.target.value.split(",");
    setstates(event.target.value);
  };
  //
  const handleSec_State = (event) => {
    let check1 = event.target.value.split(",");
    setSec_states(event.target.value);
  };
  // --------------------------------------------------------------------------------------------
  const [currentStep, setcurrentStep] = React.useState(0);
  const [steps, setsteps] = React.useState([
    {
      title: "Get Started",
    },
    {
      title: "Verify Identity ",
    },
    {
      title: "Submit Request",
    },
  ]);

  return (
    <>
      {loader == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div
            style={{
              textAlign: "-webkit-center",
            }}
          >
            <div
              style={{
                textAlign: "center",
                width: "50%",
              }}
              className="stepper"
            >
              <Stepper
                size={41}
                circleFontSize={15}
                steps={steps}
                activeStep={currentStep}
                activeColor="#4bd3c5"
                completeColor="#4bd3c5"
              />
            </div>
          </div>

          {showdiv1 ? (
            <div className="infoHolder">
              <Grid container spacing={0} className="welcometext">
                <Grid item xs={12} md={12} sm={12}>
                  <div className="gotquestions">
                    <div className="gotcss">
                      Get your digital vaccination record
                    </div>
                  </div>

                  <div className="infoaccordionbg">
                    <div className="infoaccSummary">
                      <div className="Infogrid">
                        <div>Contact Information</div>
                      </div>
                      <Grid
                        style={{ margin: "0 18px" }}
                        item
                        xs={12}
                        md={12}
                        sm={12}
                      >
                        <span style={{ color: "gray", fontSize: "16px" }}>
                          Please enter your name exactly as it appears on your
                          COVID-19 vaccination record
                        </span>
                      </Grid>

                      <Grid
                        container
                        style={{ marginLeft: "18px" }}
                        xs={12}
                        md={12}
                        sm={12}
                      >
                        <Grid
                          container
                          spacing={4}
                          xs={12}
                          md={12}
                          sm={12}
                          className="formTop"
                        >
                          <Grid item xs={12} md={4} sm={12}>
                            <TextField
                              id="standard-basic1"
                              label="First Name "
                              InputProps={{
                                className: classes.input,
                              }}
                              className={classes.textField}
                              onChange={handleChangeForm}
                              name="firstName"
                              required
                              value={formstate.firstName}
                              inputProps={{
                                maxLength: 40,
                                autoComplete: "none",
                              }}
                              onKeyUp={EnterKeynext}
                            />
                            {fnameError ? (
                              <div></div>
                            ) : (
                              <div className="errorText">
                                Please enter first name
                              </div>
                            )}
                          </Grid>
                          <Grid item xs={12} md={4} sm={12}>
                            <TextField
                              id="standard-basic1"
                              label="Middle Name"
                              InputProps={{
                                className: classes.input,
                              }}
                              className={classes.textField}
                              onChange={handleChangeForm}
                              name="middleName"
                              value={formstate.middleName}
                              inputProps={{
                                maxLength: 40,
                                autoComplete: "none",
                              }}
                              onKeyUp={EnterKeynext}
                            />
                          </Grid>
                          <Grid item xs={12} md={4} sm={12}>
                            <TextField
                              id="standard-basic1"
                              label="Last Name"
                              InputProps={{
                                className: classes.input,
                              }}
                              className={classes.textField}
                              onChange={handleChangeForm}
                              required
                              name="lastName"
                              value={formstate.lastName}
                              inputProps={{
                                maxLength: 40,
                                autoComplete: "none",
                              }}
                              onKeyUp={EnterKeynext}
                            />
                            {lnameError ? (
                              <div></div>
                            ) : (
                              <div className="errorText">
                                Please enter last name
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        {/*  */}
                        <Grid
                          container
                          spacing={4}
                          xs={12}
                          md={12}
                          sm={12}
                          className="formTop1"
                        >
                          <Grid item xs={12} md={6} sm={12}>
                            <InputLabel
                              id="demo-simple-select-label"
                              style={{ paddingBottom: "0.3em" }}
                            >
                              Phone Number *
                            </InputLabel>
                            <PhoneInput
                              country={"us"}
                              onlyCountries={["us"]}
                              countryCodeEditable={false}
                              required
                              disableDropdown={true}
                              onChange={mobileNumberChange}
                              value={newnumber}
                              onKeyUp={EnterKeynext}
                            />
                            {mobileError ? (
                              <div></div>
                            ) : (
                              <div className="errorText">
                                Please enter phone number
                              </div>
                            )}
                          </Grid>

                          <Grid
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                            item
                            xs={12}
                            md={6}
                            sm={12}
                          >
                            <Switch
                              checked={getcheckedA}
                              onChange={handleChangeText}
                              name="getcheckedA"
                            />
                            <span
                              style={{
                                color: "#808080",
                                marginLeft: "10px",
                                fontSize: "15px",
                              }}
                            >
                              Can this number receive text messages?
                            </span>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={4}
                          xs={12}
                          md={12}
                          sm={12}
                          className="formTop"
                        >
                          <Grid item xs={12} md={6} sm={12}>
                            <TextField
                              id="standard-basic1"
                              label="Email"
                              InputProps={{
                                className: classes.input,
                              }}
                              required
                              className={classes.textField}
                              onChange={handleChangeForm}
                              name="email"
                              value={formstate.email}
                              inputProps={{ maxLength: 40 }}
                              onKeyUp={EnterKeynext}
                            />

                            {emailError ? (
                              <div></div>
                            ) : (
                              <div className="errorText">
                                Please enter email-id
                              </div>
                            )}
                            {ValidemailError ? (
                              <div></div>
                            ) : (
                              <div className="errorText">
                                Please enter valid email-id
                              </div>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sm={12}
                            style={{
                              marginTop: "6px",
                              color: "gray",
                              fontSize: "15px",
                            }}
                          >
                            Email address should be unique for each individual.
                            If you do not have a unique email - you can get one
                            for free at {""}
                            <a
                              style={{ color: "#4bd3c5" }}
                              href="https://protonmail.com/signup"
                              target="_blank"
                            >
                              Proton mail.
                            </a>
                          </Grid>
                        </Grid>
                        {/*  */}
                        <Grid
                          container
                          spacing={4}
                          xs={12}
                          md={12}
                          sm={12}
                          className="formTop"
                        >
                          <Grid
                            xs={12}
                            md={6}
                            sm={12}
                            style={{ paddingLeft: "15px", paddingTop: "20px" }}
                          >
                            <InputLabel
                              id="demo-simple-select-label"
                              style={{
                                paddingBottom: "0.3em",
                                width: "100%",
                              }}
                            >
                              Date Of Birth *
                            </InputLabel>
                            <div className="dobdropdown">
                              {/* <label>Date of Birth:</label><br /> */}
                              <select
                                name="dobmonth"
                                className="monthdd"
                                id="monthdropdown"
                                onChange={(e) => {
                                  setdobmonth(e.target.value);
                                }}
                              >
                                <option
                                  value=""
                                  style={{ color: "gray" }}
                                  disabled
                                  selected
                                >
                                  Month
                                </option>
                                <option value="01">January</option>
                                <option value="02">February</option>
                                <option value="03">March</option>
                                <option value="04">April</option>
                                <option value="05">May</option>
                                <option value="06">June</option>
                                <option value="07">July</option>
                                <option value="08">August</option>
                                <option value="09">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                              </select>
                              <select
                                name="dobday"
                                id="daydropdown"
                                onChange={(e) => {
                                  setdobdate(e.target.value);
                                }}
                              >
                                <option
                                  value=""
                                  style={{ color: "gray" }}
                                  disabled
                                  selected
                                >
                                  Day
                                </option>

                                {datearr.map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                              </select>

                              <select
                                name="dobyear"
                                id="yeardropdown"
                                onChange={(e) => {
                                  setdobyear(e.target.value);
                                }}
                              >
                                <option
                                  value=""
                                  style={{ color: "gray" }}
                                  disabled
                                  selected
                                >
                                  Year
                                </option>
                                {yeararr.map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                              </select>
                            </div>
                            {dobdateError == false ? (
                              <div className="errorText">
                                Please select day{" "}
                              </div>
                            ) : dobmonthError == false ? (
                              <div className="errorText">
                                Please select month{" "}
                              </div>
                            ) : dobyearError == false ? (
                              <div className="errorText">
                                Please select year{" "}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </Grid>

                          {agelimit && getqueryString === false ? (
                            <Grid
                              item
                              xs={12}
                              md={6}
                              sm={12}
                              style={{
                                marginTop: "5px",
                                color: "gray",
                                fontSize: "15px",
                              }}
                            >
                              To request the digital vaccine record for children
                              under the age of 13 please call the hotline:{" "}
                              <a
                                style={{ color: "#4bd3c5" }}
                                // href="https://protonmail.com/signup"
                                target="_blank"
                              >
                                340-712-6299
                              </a>{" "}
                              (St. Croix) or{" "}
                              <a
                                style={{ color: "#4bd3c5" }}
                                // href="https://protonmail.com/signup"
                                target="_blank"
                              >
                                340-776-1519
                              </a>{" "}
                              (St. Thomas/St. John)
                            </Grid>
                          ) : (
                            ""
                          )}
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={12}
                          sm={12}
                          style={{ paddingBottom: "18px", paddingTop: "40px" }}
                        >
                          <div className="submit">
                            <button className="btn" onClick={ContactFormsubmit}>
                              Next
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : showdiv2 ? (
            <div className="infoHolder">
              <Grid container spacing={0} className="welcometext">
                <Grid item xs={12} md={12} sm={12}>
                  <div className="gotquestions">
                    <div className="gotcss">
                      Get your digital vaccination record
                    </div>
                  </div>

                  <div className="infoaccordionbg">
                    <div className="infoaccSummary">
                      <div className="Infogrid">
                        <div>Verification</div>
                      </div>
                      <Grid
                        container
                        style={{
                          padding: "0  18px",
                        }}
                        xs={12}
                        md={12}
                        sm={12}
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          sm={12}
                          style={{ color: "gray", fontSize: "16px" }}
                        >
                          To keep your data secure and confirm your identity,
                          please upload a picture of your driver's license or
                          other government issued ID.
                        </Grid>
                        <Grid
                          container
                          spacing={4}
                          xs={12}
                          md={12}
                          sm={12}
                          className="formTop"
                        >
                          {/* 1st div */}
                          {/* <Grid item xs={12} md={6} sm={12}>
                            <FormControl
                              className={classes.formControl}
                              style={{ width: "100%" }}
                            >
                              <InputLabel
                                id="demo-simple-select-label"
                                style={{
                                  paddingBottom: "0.3em",
                                  width: "100%",
                                }}
                              >
                                Where did you receive your vaccine (1st or 2nd
                                dose)?
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="vaccineLoc"
                                value={vaccineLoc}
                                onChange={handleLocation}
                                required
                              >
                                {stateOrg.map((item) => (
                                  <MenuItem
                                    value={
                                      item.organizationName + "," + item.labId
                                    }
                                  >
                                    {item.organizationName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {vacclabError ? (
                              <div></div>
                            ) : (
                              <div className="errorText">
                                Please select vaccine location
                              </div>
                            )}
                          </Grid> */}

                          {/* 2nd div */}
                          {/* <Grid item xs={12} md={12} sm={12}>
                            <InputLabel
                              id="demo-simple-select-label"
                              style={{ paddingBottom: "0.3em", width: "100%" }}
                            >
                              When did you receive your vaccine (select 1st or
                              2nd dose date)
                            </InputLabel>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid item xs={12} md={6} sm={12}>
                                <KeyboardDatePicker
                                  clearable
                                  value={selectDateVaccine}
                                  onChange={(date) => handleDateChange(date)}
                                  format="MM/dd/yyyy"
                                  label="Vaccine date (MM/DD/YYYY)"
                                  maxDate={new Date()}
                                  autoOk
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                            {vaccdateError ? (
                              <div></div>
                            ) : (
                              <div className="errorText">
                                Please select vaccine date
                              </div>
                            )}
                          </Grid> */}
                          {/* <Grid
                            item
                            xs={12}
                            md={12}
                            sm={12}
                            style={{ marginTop: "-15px", color: "gray" }}
                          >
                            (If 1st dose and 2nd dose date are different only
                            one needs to be selected.)
                          </Grid> */}

                          <Grid item xs={12} md={6} sm={12}>
                            <div className="uploaddiv">
                              {drivingFile ? (
                                <div>
                                  Click 'Next' to continue or upload a new image{" "}
                                  <br />
                                  <span style={{ fontSize: "14px" }}>
                                    Please make sure your name and date of birth
                                    are visible in the image
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <div
                              style={{
                                display: "inline-flex",
                                flexDirection: "column",
                                textAlign: "center",
                              }}
                            >
                              <label
                                for="file-upload"
                                class="custom-file-upload"
                              >
                                {drivingFile
                                  ? drivingFile.length > 0
                                    ? drivingFile[0].name
                                    : "Upload Photo ID"
                                  : "Upload Photo ID"}
                              </label>

                              <span
                                style={{
                                  paddingTop: "5px",
                                  fontSize: "10px",
                                  color: "gray",
                                }}
                              >
                                Supports png, jpeg, jpg, pdf, heif
                              </span>

                              <input
                                id="file-upload"
                                data-max-size="32154"
                                accept=".png, .jpeg, .jpg, .pdf, .heif"
                                type="file"
                                name="file"
                                onChange={DrivingHandler}
                              />
                            </div>
                            {drivingError ? (
                              <div></div>
                            ) : (
                              <div
                                className="errorText"
                                style={{ marginTop: "15px" }}
                              >
                                Please upload photo ID
                              </div>
                            )}
                            {!drivingFilesize ? (
                              <div></div>
                            ) : (
                              <div
                                className="errorText"
                                style={{ marginTop: "15px" }}
                              >
                                File size should not exceed more than 4 mb
                              </div>
                            )}
                            {drivingTypeError ? (
                              <div
                                className="errorText"
                                style={{ marginTop: "15px" }}
                              >
                                Please upload image with required type
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6} sm={12}>
                            {drivingFile &&
                              drivingFile.length > 0 &&
                              drivingFile[0].type != "application/pdf" &&
                              drivingFile[0].type != "image/heif" &&
                              drivingFile[0].type !== "" ? (
                              <div style={{ width: "200px" }}>
                                <img
                                  alt="Preview Image"
                                  src={
                                    drivingFile
                                      ? drivingFile.length > 0
                                        ? drivingimgShow
                                        : ""
                                      : ""
                                  }
                                  style={{ width: "150px" }}
                                ></img>
                              </div>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          md={12}
                          sm={12}
                          style={{
                            marginTop: "20px",
                            paddingTop: "30px",
                            paddingBottom: "20px",
                          }}
                        >
                          <div className="submit">
                            <button className="btn1" onClick={Verifiysubmit}>
                              Next
                            </button>
                          </div>
                          {ApiError === true ? (
                            <div
                              className="errorText"
                              style={{ marginTop: "15px", textAlign: "center" }}
                            >
                              Unable to send notification please check your
                              email and mobile number
                            </div>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : showdiv3 ? (
            <div className="infoHolder">
              <Grid container spacing={0} className="welcometext">
                <Grid item xs={12} md={12} sm={12}>
                  <div className="gotquestions">
                    <div className="gotcss">
                      Get your digital vaccination record
                    </div>
                  </div>

                  <div className="infoaccordionbg">
                    <div className="infoaccSummary">
                      <div className="Infogrid">
                        {/* <div>Verification</div> */}
                      </div>
                      <Grid
                        container
                        style={{
                          marginLeft: "18px",
                        }}
                        xs={12}
                        md={12}
                        sm={12}
                      >
                        <Grid
                          container
                          spacing={4}
                          xs={12}
                          md={12}
                          sm={12}
                          className="formTop"
                        >
                          {/* 1st div */}
                          {/* <Grid item xs={12} md={12} sm={12}> */}
                          <Grid
                            item
                            xs={12}
                            md={12}
                            sm={12}
                            style={{ marginTop: "7px" }}
                          >
                            <FormControl
                              className={classes.formControl}
                              style={{ width: "100%" }}
                            >
                              <InputLabel
                                id="demo-simple-select-label"
                                style={{
                                  paddingBottom: "0.3em",
                                  width: "100%",
                                }}
                              >
                                Vaccine name *
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                name="vaccinetype"
                                value={vaccinetype}
                                onChange={handlevaccinetype}
                                required
                              >
                                {VaccineName.map((item) => (
                                  <MenuItem value={item.vaccineName}>
                                    {item.vaccineName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {vaccinenameError === false ? (
                              <div
                                className="errorText"
                                style={{ marginTop: "20px" }}
                              >
                                Please select vacccine name
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            sm={12}
                            style={{
                              fontSize: "12px",
                              color: "gray",
                              fontSize: "16px",
                            }}
                          >
                            To confirm your vaccination status, please upload a
                            picture of your most recent vaccination record or your SMART
                            Health card.
                          </Grid>

                          <Grid item xs={12} md={6} sm={12}>
                            <div className="uploaddiv">
                              {vaccineFile ? (
                                <div>
                                  Click 'Next' to continue or upload a different
                                  image
                                  <br />
                                  <span style={{ fontSize: "14px" }}>
                                    Please make sure your name and date of birth
                                    are visible in the image
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              style={{
                                display: "inline-flex",
                                flexDirection: "column",
                                textAlign: "center",
                              }}
                            >
                              <label
                                for="file-upload1"
                                class="custom-file-upload"
                              >
                                {vaccineFile
                                  ? vaccineFile.length > 0
                                    ? vaccineFile[0].name
                                    : "Upload updated vaccination record"
                                  : "Upload updated vaccination record"}
                              </label>
                              <span
                                style={{
                                  paddingTop: "5px",
                                  fontSize: "10px",
                                  color: "gray",
                                }}
                              >
                                Supports png, jpeg, jpg, pdf, heif
                              </span>
                              <input
                                id="file-upload1"
                                accept=".png, .jpeg, .jpg, .pdf , .heif"
                                data-max-size="32154"
                                type="file"
                                name="file1"
                                onChange={VaccineHandler}
                              />
                            </div>
                            {vaccineError ? (
                              <div></div>
                            ) : (
                              <div
                                className="errorText"
                                style={{ marginTop: "20px" }}
                              >
                                Please upload vaccine record
                              </div>
                            )}
                            {!vaccineFilesize ? (
                              <div></div>
                            ) : (
                              <div
                                className="errorText"
                                style={{ marginTop: "20px" }}
                              >
                                File size should not exceed more than 4 mb
                              </div>
                            )}
                            {vaccineTypeError ? (
                              <div
                                className="errorText"
                                style={{ marginTop: "15px" }}
                              >
                                Please upload image with required type
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6} sm={12}>
                            {vaccineFile &&
                              vaccineFile.length > 0 &&
                              vaccineFile[0].type != "application/pdf" &&
                              vaccineFile[0].type != "image/heif" &&
                              vaccineFile[0].type !== "" ? (
                              <div style={{ width: "200px" }}>
                                <img
                                  alt="Preview Image"
                                  src={
                                    vaccineFile
                                      ? vaccineFile.length > 0
                                        ? VaccineimgShow
                                        : ""
                                      : ""
                                  }
                                  style={{ width: "150px" }}
                                ></img>
                              </div>
                            ) : (
                              ""
                            )}
                          </Grid>

                          {/* </Grid> */}

                          {vaccinetype != "" &&
                            vaccinetype === "Janssen COVID-19 Vaccine" ? null : (
                            <Grid item xs={12} md={12} sm={12}>
                              <FormLabel
                                component="legend"
                                className="paddingAdj"
                              >
                                Have you received both doses? *
                              </FormLabel>
                              <RadioGroup
                                aria-label="symtomatic"
                                name="Covid19vaccine"
                                value={Covid19vaccine}
                                onChange={handleCovid19vaccine}
                                style={{ display: "block", marginTop: "10px" }}
                              >
                                <FormControlLabel
                                  value="Y"
                                  control={<Radio />}
                                  label="Yes"
                                />
                                <FormControlLabel
                                  value="N"
                                  control={<Radio />}
                                  label="No"
                                />
                              </RadioGroup>

                              {receivedError === false ? (
                                <div
                                  className="errorText"
                                  style={{ marginTop: "20px" }}
                                >
                                  Please confirm have you received both doses
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </Grid>
                          )}

                {doseList.map((dose, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Typography className="pt mx" variant="subtitle1">
                        <span style={{ margin: "0 5px" }}>
                          {dosecountList[i]}
                        </span>
                      </Typography>
                      <Grid
                        container
                        spacing={3}
                        style={{ paddingTop: "10px" }}
                        className="px"
                      >
                        <Grid item xs={12} md={6} sm={12}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              style={{ width: "100%" }}
                              clearable
                              value={dose.vaccineDate}
                              className="dateSelection"
                              onChange={(e) =>
                                doseOnchange(e, i, "vaccineDate")
                              }
                              format="MM/dd/yyyy"
                              label={`${dosecountList[i]} date (MM/DD/YYYY)`}
                              maxDate={new Date()}
                              required
                              autoOk
                            />
                          </MuiPickersUtilsProvider>
                          {vaccinationdateError === true && i === 0 &&
                            dose.vaccineDate === null ? (
                            <div className="errorText">
                              Please enter the Vaccine date
                            </div>
                            ) : secvaccinationdateError === true && i === 1 &&
                            dose.vaccineDate === null ? (
                            <div className="errorText">{secvaccinationdateError}
                              Please enter the Vaccine date
                            </div>
                            ) : (
                            <div></div>
                          )}
                        </Grid>
                        <Grid item xs={12} md={3} sm={12}>
                          <TextField
                            className="form_textfield mt"
                            variant="standard"
                            placeholder="Lot # *"
                            autoComplete="off"
                            disabled={dose.first_notsure == true ? true : false}
                            value={
                              dose.first_notsure === false
                                ? dose.lotNumber
                                : dose.first_notsure === true
                                  ? "I'm not sure"
                                  : dose.lotNumber
                            }
                            onChange={(e) => doseOnchange(e, i, "lotNumber")}
                            onKeyUp={EnterKeynext}
                            required
                            name="lotNumber"
                            inputProps={{ maxLength: 40 }}
                          />
                          {lotError === true && i === 0 &&
                            dose.lotNumber.trim() === "" &&
                            dose.first_notsure === false ? (
                            <div className="errorText">
                              Please enter LOT number
                            </div>
                          ) : seclotError === true && i === 1 &&
                            dose.lotNumber.trim() === "" &&
                            dose.first_notsure === false ? (
                            <div className="errorText">
                              Please enter LOT number
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </Grid>
                        <Grid item xs={12} md={3} sm={12}>
                          <FormControlLabel
                            className="form-not-sure mt "
                            control={
                              <Checkbox
                                style={{ paddingRight: "10px" }}
                                onChange={(e) =>
                                  doseOnchange(e, i, "first_notsure")
                                }
                                name="first_notsure"
                                checked={dose.first_notsure}
                              />
                            }
                            label=" I'm not sure"
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={12}>
                          <FormControl
                            className="form-dropdown"
                            fullWidth
                            variant="standard"
                          >
                            <InputLabel>Country*</InputLabel>
                            <Select
                              name="country"
                              value={dose.country}
                              onChange={(e) => doseOnchange(e, i, "country")}
                              required
                            >
                              {countryList.map((item) => (
                                <MenuItem value={item.countryCode}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {countryError === true && dose.country === "" ? (
                            <div className="errorText">
                              Please select the Country
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </Grid>
                        {loader == true ? (
                          <div className="stateLoader">
                            <CircularProgress />
                          </div>
                        ) : (
                          <Grid item xs={12} md={6} sm={12}>
                            <FormControl
                              className="form-dropdown"
                              fullWidth
                              variant="standard"
                            >
                              <InputLabel>State/Territory*</InputLabel>
                              <Select
                                name="state"
                                value={dose.state}
                                onChange={(e) => doseOnchange(e, i, "state")}
                                required
                              >
                                {dose.stateList.map((item) => (
                                  <MenuItem
                                    value={item[1].stateCode + "," + item[0]}
                                  >
                                    {item[0]}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            {stateError === true && dose.state === "" ? (
                              <div className="errorText">
                                Please select the State
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </Grid>
                        )}

                        <Grid item xs={12} md={6} sm={12}>
                          <FormControl
                            className={classes.formControl}
                            style={{ width: "100%" }}
                          >
                            <InputLabel
                              id="demo-simple-select-label"
                              style={{
                                paddingBottom: "0.3em",
                                width: "100%",
                              }}
                            >
                              {dosecountList[i]} location *
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              onChange={(e) => doseOnchange(e, i, "location")}
                              required
                              name="location"
                              value={dose.doseLoc}
                            >
                              {stateOrg.map((item) => (
                                <MenuItem
                                  value={
                                    item.organizationName + "+" + item.labId
                                  }
                                >
                                  {item.organizationName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                          {locationError === true && i == 0 ? (
                            <div
                              className="errorText"
                              style={{ marginTop: "20px" }}
                            >
                              Please enter the location
                            </div>
                          ) : seclocationError === true && i == 1 ? (
                            <div
                              className="errorText"
                              style={{ marginTop: "20px" }}
                            >
                              Please enter the location
                            </div>
                          ) :(
                            <div></div>
                          )}
                        </Grid>

                        <Grid item xs={12} md={6} sm={12}>
                            {dose.doseLoc === "Other+32" && (
                              <form autoComplete="off">
                                <TextField
                                  value={dose.otherLabName}
                                  id="standard-basic"
                                  label="Please specify the location *"
                                  onChange={(e) => doseOnchange(e, i, "otherLabName")}
                                  name="otherLabName"
                                />
                              </form>
                            )}

                            {dose.doseLoc === "Other+32" &&
                              OtherlocationError === true && i === 0 ? (
                              <div
                                className="errorText"
                                style={{ marginTop: "20px" }}
                              >
                                Please enter the location
                              </div>
                            ) : secOtherlocationError === true && i === 1 ? (
                              <div
                                className="errorText"
                                style={{ marginTop: "20px" }}
                              >
                                Please enter the location
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </Grid>


{/*                         <Grid item xs={12} md={12} sm={12}>
                          <div className="removeDose">
                            {i ==
                              (doseList.length === 1
                                ? doseList.length
                                : doseList.length - 1) && (
                                <span
                                  style={{
                                    backgroundColor: "#f07167",
                                    padding: "3px 8px",
                                    borderRadius: "0.4rem",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  <DeleteIcon
                                    style={{ width: "20px", marginRight: "5px" }}
                                  />{" "}
                                  Remove
                                </span>
                              )}
                          </div>
                        </Grid> */}
                      </Grid>
                    </React.Fragment>
                  );
                })}

              </Grid>

                        {/*  */}
                        <Grid
                          item
                          xs={12}
                          md={12}
                          sm={12}
                          style={{ paddingBottom: "18px", paddingTop: "40px" }}
                        >
                          <div className="submit">
                            <button
                              className="btn"
                              onClick={VerifiyVaccination}
                            >
                              Next
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : showdiv4 ? (
            <div className="infoHolder">
              <Grid container spacing={0} className="welcometext">
                <Grid item xs={12} md={12} sm={12}>
                  <div className="gotquestions">
                    <div className="gotcss">
                      Get your digital vaccination record
                    </div>
                  </div>

                  <div className="infoaccordionbg">
                    <div className="infoaccSummary">
                      <div className="ThankInfo">
                        <div className="Thankyou">Thank you! </div>
                        <div>Your Information has been received. </div>
                      </div>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        sm={12}
                        style={{ paddingTop: "50px" }}
                      >
                        <div className="submit">
                          <button
                            className="btn3"
                            onClick={Backbtn}
                            style={{ width: "30%", marginRight: "0px" }}
                          >
                            Submit another request
                          </button>
                        </div>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      )}
    </>
  );
};

export default PatientInfo;
