import React, { useEffect } from "react";
import Viewer, { Worker } from "@phuocng/react-pdf-viewer";
import "@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css";
import { useHistory } from "react-router-dom";
import Applewallet from "../Assets/Apple_Wallet.svg";
import Googlewallet from "../Assets/GooglePay.svg";

export default function Walletcard(props) {
    console.log("props", props.location);
    const history = useHistory();
    const Back = () => {
        history.push({
            pathname: "/pdf",
        });
    };

    return (
        <>
            <div>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                    <div className="pdfbody">
                        <Viewer defaultScale={1.5} fileUrl={localStorage.getItem("wallet")} />
                    </div>
                </Worker>
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <div>
                    <button className="pdfbtn pdfbtn1" onClick={Back}>
                        Back
                    </button>
                </div>
            </div>
        </>
    );
}
