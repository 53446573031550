import React from "react";
import Grid from "@material-ui/core/Grid";
import "./Login.css";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as actions from "../../Action/index";
import { useHistory } from "react-router";
import { toast, ToastContainer } from "react-toastify";

export default function LoginMode(props) {
  const history = useHistory();
  const [checkotp, setcheckotp] = React.useState("");
  const [loader, setloader] = React.useState(false);
  const handleChange = (event) => {
    setcheckotp(event.target.value);
  };
  const [getError, Seterror] = React.useState(false);
  const formSubmit = (e) => {
    if (checkotp === "") {
      Seterror(true);
    } else {
      setloader(true);
      console.log(checkotp);
      let data = {
        email: checkotp === "email" ? props.location.state.email : null,
        mobileNumber:
          checkotp === "mobile" ? props.location.state.mobileNumber : null,
        messageCode: null,
        patientId: props.location.state.patientId,
        firstName: props.location.state.firstName,
      };
      actions.SendOTP_Action(data, (res) => {
        if (res.statusCode == "200") {
          history.push({
            pathname: "/login-otp",
            state: data,
          });
        } else {
          setloader(false);
          toast.error(res.errorMessage)
        }
      });
    }
  };

  let emailval = `Via email at ${props.location.state.email}`;
  let mobilelval = `Via text at  *** -${props.location.state.mobileNumber.slice(
    8,
    12
  )}`;
  return (
    <>
    <ToastContainer />
      {loader == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="Otp_request_loginMode">
            <p>Please choose how you want to receive your </p>
            <p style={{ marginTop: "20px", marginbottom: "40px" }}>
              <b>verification code</b>{" "}
            </p>

            <div>
              <RadioGroup
                aria-label="testType"
                name="testType"
                value={checkotp}
                onChange={handleChange}
                style={{ display: "block" }}
                className="LoginMethod"
              >
                <FormControlLabel
                  className="email_form_control"
                  value="email"
                  control={<Radio />}
                  label={emailval}
                />
                <FormControlLabel
                  className="mobile_form_control"
                  value="mobile"
                  control={<Radio />}
                  label={mobilelval}
                />
              </RadioGroup>
            </div>
            {getError ? (
              <div className="errorText">
                Please choose how you want to receive
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="loginSubmitHolder">
            <Grid item xs={12} md={12} sm={12}>
              <div className="submit">
                <button className="btn retrivalBtn" onClick={formSubmit}>
                  Next
                </button>
              </div>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
}
