import React, { useEffect, useState } from "react";
import { Grid, CircularProgress } from "@material-ui/core";

import "../PatientInfo/patientInfo.css";

import { ToastContainer, toast } from "react-toastify";
import * as actions from "../../Action/index";
import "react-toastify/dist/ReactToastify.css";

const Reupload = () => {
  const [loader, setloader] = React.useState(false);
  const [ApiError, SetApiError] = React.useState(false);
  const [drivingError, setdrivingError] = React.useState(true);
  const [vaccineError, setvaccineError] = React.useState(true);
  const [drivingFile, setdrivingFile] = React.useState();
  const [drivingFilesize, setdrivingFilesize] = React.useState(false);
  const [drivingTypeError, setdrivingTypeError] = React.useState(false);
  const [vaccineFile, setvaccineFile] = React.useState();
  const [vaccineFilesize, setvaccineFilesize] = React.useState(false);
  const [vaccineTypeError, setvaccineTypeError] = React.useState(false);
  const [drivingimgShow, setdrivingimgshow] = React.useState("");
  const [VaccineimgShow, setVaccineimgshow] = React.useState("");
  const [showdiv1, setshowdiv1] = React.useState(true);
  const [showdiv2, setshowdiv2] = React.useState(true);
  //==== Driving Handler function ====//
  const DrivingHandler = (event) => {
    const fileTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
      "image/heif",
    ];
    if (
      event.target.files.length != 0 &&
      fileTypes.includes(event.target.files[0].type)
    ) {
      if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "image/jpeg" ||
          event.target.files[0].type === "image/png" ||
          event.target.files[0].type === "image/jpg")
      ) {
        setdrivingFile(event.target.files);
        setdrivingimgshow(
          event.target.files.length != 0
            ? URL.createObjectURL(event.target.files[0])
            : ""
        );

        setdrivingTypeError(false);
        setdrivingError(true);
      } else if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "application/pdf" ||
          event.target.files[0].type === "image/heif")
      ) {
        setdrivingFile(event.target.files);
        setdrivingimgshow("");
        setdrivingTypeError(false);
        setdrivingError(true);
      }
    } else {
      setdrivingimgshow("");
      setdrivingFile();
      setdrivingTypeError(true);
    }

    const fileSize =
      event.target.files.length != 0
        ? event.target.files[0].size / 1024 / 1024
        : "";
    if (event.target.files.length != 0 && fileSize > 4) {
      setdrivingFilesize(true);
    } else {
      setdrivingFilesize(false);
    }
  };
  //==== Vaccination Handler function ====//
  const VaccineHandler = (event) => {
    const fileTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
      "image/heif",
    ];
    if (
      event.target.files.length != 0 &&
      fileTypes.includes(event.target.files[0].type)
    ) {
      if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "image/jpeg" ||
          event.target.files[0].type === "image/png" ||
          event.target.files[0].type === "image/jpg")
      ) {
        setvaccineFile(event.target.files);
        setVaccineimgshow(
          event.target.files.length != 0
            ? URL.createObjectURL(event.target.files[0])
            : ""
        );

        setvaccineTypeError(false);
        setvaccineError(true);
      } else if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "application/pdf" ||
          event.target.files[0].type === "image/heif")
      ) {
        setvaccineFile(event.target.files);
        setVaccineimgshow("");
        setvaccineTypeError(false);
        setvaccineError(true);
      }
    } else {
      setVaccineimgshow("");
      setvaccineFile();
      setvaccineTypeError(true);
    }

    const fileSize =
      event.target.files.length != 0
        ? event.target.files[0].size / 1024 / 1024
        : "";
    if (event.target.files.length != 0 && fileSize > 4) {
      setvaccineFilesize(true);
    } else {
      setvaccineFilesize(false);
    }
  };

  //========Submit Function===========//

  const EnterKeynext = (evt) => {
    if (evt.keyCode == 13) {
      Verifiysubmit();
    }
  };

  const Verifiysubmit = (e) => {
    if (
      (new URLSearchParams(window.location.search).get("require") == "id" || 
      new URLSearchParams(window.location.search).get("require") == "both") &&
      (drivingFile === undefined ||
      drivingFile === "" ||
      drivingFile.length == 0)
    ) {
      setdrivingError(false);
      setvaccineError(true);
      setdrivingTypeError(false);
      setvaccineTypeError(false);
    } else if (drivingFilesize == true) {
      return 0;
    }
    else if (
      (new URLSearchParams(window.location.search).get("require") == "card" || 
      new URLSearchParams(window.location.search).get("require") == "both") &&
      (vaccineFile === undefined ||
        vaccineFile === "" ||
        vaccineFile.length == 0)
    ) {
      setvaccineError(false);
      setvaccineTypeError(false);
    } else if (
      (new URLSearchParams(window.location.search).get("require") == "card" || 
      new URLSearchParams(window.location.search).get("require") == "both") &&
      vaccineFilesize == true
    ) {
      return 0;
    }
    else {
      setdrivingError(true);

      if (new URLSearchParams(window.location.search).get("type") == "new") {
        setloader(true);
        const formData = new FormData();
        formData.append(
          "id",
          new URLSearchParams(window.location.search).get("id")
        );
        if(drivingFile != undefined &&
          drivingFile != "" &&
          drivingFile.length != 0){
            formData.append("licenceFile", drivingFile[0]);
          }
        if(vaccineFile != undefined &&
          vaccineFile != "" &&
          vaccineFile.length != 0){
            formData.append("cardFile", vaccineFile[0]);
          }
        actions.ReloadNew_Action(formData, (res) => {
          if (res.statusCode == "200") {
            setdrivingFile("");
            setvaccineFile("");
            setshowdiv1(false);
            setshowdiv2(true);
            setloader(false);
          } else {
            toast.error(res.errorMessage);
          }
        });
      } else if (new URLSearchParams(window.location.search).get("type") == "exist") {
        setloader(true);
        const formData = new FormData();
        formData.append(
          "id",
          new URLSearchParams(window.location.search).get("id")
        );
        if(drivingFile != undefined &&
          drivingFile != "" &&
          drivingFile.length != 0){
            formData.append("licenceFile", drivingFile[0]);
          }
        if(vaccineFile != undefined &&
          vaccineFile != "" &&
          vaccineFile.length != 0){
            formData.append("cardFile", vaccineFile[0]);
          }
        actions.ReloadExist_Action(formData, (res) => {
          if (res.statusCode == "200") {
            console.log(res);
            setshowdiv1(false);
            setshowdiv2(true);
            setdrivingFile("");
            setvaccineFile("");
            setloader(false);
          } else {
            toast.error(res.errorMessage);
          }
        });
      } else {
        setloader(true);
        const formData = new FormData();
        formData.append(
          "id",
          new URLSearchParams(window.location.search).get("id")
        );
        if(drivingFile != undefined &&
          drivingFile != "" &&
          drivingFile.length != 0){
            formData.append("licenceFile", drivingFile[0]);
          }
        if(vaccineFile != undefined &&
          vaccineFile != "" &&
          vaccineFile.length != 0){
            formData.append("cardFile", vaccineFile[0]);
          }
        actions.ReloadMissing_Action(formData, (res) => {
          if (res.statusCode == "200") {
            console.log(res);
            setshowdiv1(false);
            setshowdiv2(true);
            setdrivingFile("");
            setvaccineFile("");
            setloader(false);
          } else {
            toast.error(res.errorMessage);
          }
        });
      }
    }
  };

  // --------------------------------------------------------//

  return (
    <>
      <ToastContainer />
      {loader == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <>
          {" "}
          {showdiv1 ? (
            <div className="infoHolder">
              <Grid container spacing={0} className="welcometext">
                <Grid item xs={12} md={12} sm={12}>
                  <div className="gotquestions">
                    <div className="gotcss">
                      Your digital vaccination record is almost ready!
                    </div>
                  </div>

                  <div className="infoaccordionbg">
                    <div className="infoaccSummary">
                      <div className="Infogrid">
                        {new URLSearchParams(window.location.search).get("require") == "id" ? 
                          <div>ID Verification Required</div>
                          : new URLSearchParams(window.location.search).get("require") == "card" ?
                          <div>Proof of Vaccination Required</div>
                          : <div>ID Verification and Proof of Vaccination Required</div>
                        }                        
                      </div>
                      <Grid
                        container
                        style={{
                          padding: "0  18px",
                        }}
                        xs={12}
                        md={12}
                        sm={12}
                      >
                        {(new URLSearchParams(window.location.search).get("require") == "id"
                         || new URLSearchParams(window.location.search).get("require") == "both") ? (
                          <>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              sm={12}
                              style={{ color: "gray", fontSize: "16px" }}
                            >
                              To keep your data secure and confirm your identity,
                              please upload a picture of your driver's license or
                              other government issued ID.
                            </Grid>
                            <Grid item xs={12} md={12} sm={12}>
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "15px",
                                }}
                              >
                                Please note portrait photographs or selfies cannot
                                be used for ID verification.
                              </span>
                            </Grid>
                            <Grid
                              container
                              spacing={4}
                              xs={12}
                              md={12}
                              sm={12}
                              className="formTop"
                            >
                              <Grid item xs={12} md={6} sm={12}>
                                <div className="uploaddiv">
                                  {drivingFile ? (
                                    <div>
                                      {/* Click 'Next' to continue or upload a new image{" "}
                                      <br /> */}
                                      <span style={{ fontSize: "14px" }}>
                                        Please make sure your name and date of birth
                                        are visible in the image
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                <div
                                  style={{
                                    display: "inline-flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                  }}
                                >
                                  <label
                                    for="file-upload"
                                    class="custom-file-upload1"
                                  >
                                    {drivingFile
                                      ? drivingFile.length > 0
                                        ? drivingFile[0].name
                                        : "Upload Photo ID"
                                      : "Upload Photo ID"}
                                  </label>

                                  <span
                                    style={{
                                      paddingTop: "5px",
                                      fontSize: "10px",
                                      color: "gray",
                                    }}
                                  >
                                    Supports png, jpeg, jpg, pdf, heif
                                  </span>

                                  <input
                                    id="file-upload"
                                    data-max-size="32154"
                                    accept=".png, .jpeg, .jpg, .pdf, .heif"
                                    type="file"
                                    name="file"
                                    onKeyUp={EnterKeynext}
                                    onChange={DrivingHandler}
                                  />
                                </div>
                                {drivingError ? (
                                  <div></div>
                                ) : (
                                  <div
                                    className="errorText"
                                    style={{ marginTop: "15px" }}
                                  >
                                    Please upload photo ID
                                  </div>
                                )}
                                {!drivingFilesize ? (
                                  <div></div>
                                ) : (
                                  <div
                                    className="errorText"
                                    style={{ marginTop: "15px" }}
                                  >
                                    File size should not exceed more than 4 mb
                                  </div>
                                )}
                                {drivingTypeError ? (
                                  <div
                                    className="errorText"
                                    style={{ marginTop: "15px" }}
                                  >
                                    Please upload image with required type
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </Grid>
                              <Grid item xs={12} md={6} sm={12}>
                                {drivingFile &&
                                drivingFile.length > 0 &&
                                drivingFile[0].type != "application/pdf" &&
                                drivingFile[0].type != "image/heif" &&
                                drivingFile[0].type !== "" ? (
                                  <div style={{ width: "200px" }}>
                                    <img
                                      alt="Preview Image"
                                      src={
                                        drivingFile
                                          ? drivingFile.length > 0
                                            ? drivingimgShow
                                            : ""
                                          : ""
                                      }
                                      style={{ width: "150px" }}
                                    ></img>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Grid>
                          </>
                          ) : (
                            <Grid></Grid>
                        )}
                        {/* place here second grid */}

                        {(new URLSearchParams(window.location.search).get("require") == "card"
                         || new URLSearchParams(window.location.search).get("require") == "both") ? (
                          <>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              sm={12}
                              style={{
                                marginTop: "20px",
                                color: "gray",
                                fontSize: "16px",
                              }}
                            >
                              To confirm your vaccination status, please upload
                              a picture of your vaccination record or your SMART
                              Health card.
                            </Grid>
                            <Grid
                              container
                              spacing={4}
                              xs={12}
                              md={12}
                              sm={12}
                              className="formTop"
                            >
                              <Grid item xs={12} md={6} sm={12}>
                                <div className="uploaddiv">
                                  {vaccineFile ? (
                                    <div>
                                      {/* Click 'Next' to continue or upload a
                                      different image
                                      <br /> */}
                                      <span style={{ fontSize: "14px" }}>
                                        Please make sure all doses are visible in the image.
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div
                                  style={{
                                    display: "inline-flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                  }}
                                >
                                  <label
                                    for="file-upload1"
                                    class="custom-file-upload"
                                  >
                                    {vaccineFile
                                      ? vaccineFile.length > 0
                                        ? vaccineFile[0].name
                                        : "Upload Vaccination Record"
                                      : "Upload Vaccination Record"}
                                  </label>
                                  <span
                                    style={{
                                      paddingTop: "5px",
                                      fontSize: "10px",
                                      color: "gray",
                                    }}
                                  >
                                    Supports png, jpeg, jpg, pdf, heif
                                  </span>
                                  <input
                                    id="file-upload1"
                                    accept=".png, .jpeg, .jpg, .pdf , .heif"
                                    data-max-size="32154"
                                    type="file"
                                    name="file1"
                                    onChange={VaccineHandler}
                                  />
                                </div>
                                {vaccineError ? (
                                  <div></div>
                                ) : (
                                  <div
                                    className="errorText"
                                    style={{ marginTop: "20px" }}
                                  >
                                    Please upload vaccine record
                                  </div>
                                )}
                                {!vaccineFilesize ? (
                                  <div></div>
                                ) : (
                                  <div
                                    className="errorText"
                                    style={{ marginTop: "20px" }}
                                  >
                                    File size should not exceed more than 4 mb
                                  </div>
                                )}
                                {vaccineTypeError ? (
                                  <div
                                    className="errorText"
                                    style={{ marginTop: "15px" }}
                                  >
                                    Please upload image with required type
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </Grid>
                              <Grid item xs={12} md={6} sm={12}>
                                {vaccineFile &&
                                vaccineFile.length > 0 &&
                                vaccineFile[0].type != "application/pdf" &&
                                vaccineFile[0].type != "image/heif" &&
                                vaccineFile[0].type !== "" ? (
                                  <div style={{ width: "200px" }}>
                                    <img
                                      alt="Preview Image"
                                      src={
                                        vaccineFile
                                          ? vaccineFile.length > 0
                                            ? VaccineimgShow
                                            : ""
                                          : ""
                                      }
                                      style={{ width: "150px" }}
                                    ></img>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Grid>
                          </>
                        ) : (
                          <Grid></Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          md={12}
                          sm={12}
                          style={{
                            marginTop: "20px",
                            paddingTop: "30px",
                            paddingBottom: "20px",
                          }}
                        >
                          <div className="submit">
                            <button className="btn1" onClick={Verifiysubmit}>
                              Submit
                            </button>
                          </div>
                          {ApiError === true ? (
                            <div
                              className="errorText"
                              style={{ marginTop: "15px", textAlign: "center" }}
                            >
                              Unable to send notification please check your
                              email and mobile number
                            </div>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : showdiv2 ? (
            <div className="infoHolder">
              <Grid container spacing={0} className="welcometext">
                <Grid item xs={12} md={12} sm={12}>
                  <div className="gotquestions">
                    <div className="gotcss">
                      Get your digital vaccination record
                    </div>
                  </div>

                  <div className="infoaccordionbg">
                    <div className="infoaccSummary">
                      <div className="ThankInfo">
                        <div className="Thankyou">Thank you! </div>
                        <div>Your Information has been received. </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div></div>
          )}
        </>
      )}
    </>
  );
};

export default Reupload;
