import * as ActionConstants from '../Constant/index.js'
const reducer = (state = { data: [] }, action) => {
    switch (action.type) {
        // ---------------------------------------------------------------------------------------------------------------------- //
        case ActionConstants.Book_Vaccination_Constant:
            return { ...state, ...{ menucheck: action.payload } };
            case ActionConstants.Book_Testing_Constant:
                return { ...state, ...{ menucheck: action.payload } };
        default:
            return state;
    }
}

export default reducer;