import axios from "axios";
import * as API_URL from "../API-URL/index.js";

// Auth login

export function SendNotification_API(data, res) {
  axios
    .post(API_URL.SEND_NOTIFICATION_URL, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

export function MissingDose_API(data, res) {
  axios
    .post(API_URL.MISSING_DOSE_URL, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}
// Auth Send OTP

export function SendOTP_API(data, res) {
  axios
    .post(API_URL.SENDOTP_URL, data)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

// Auth Validate OTP
export function ValidateOTP_API(data, res) {
  axios
    .post(API_URL.VALIDATEOTP_URL + "?otp=" + data.otp)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

// Auth Refresh Token
export function GetOTP_API(data, res) {
  axios
    .post(API_URL.GET_OTP_URL, data)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}
// Get organizations
export function GetOrg_API(res) {
  axios
    .get(API_URL.GET_ORG_URL)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}
export function GetVaccination_API(res) {
  axios
    .get(API_URL.GET_VACCINATION_URL)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}
export function GetCounties_API(res) {
  axios
    .get(API_URL.GET_COUNTIES_URL)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}
export function AddVacination_API(data, res) {
  axios
    .post(API_URL.ADD_VACINATION_URL, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

export function ReuploadNew_API(data, res) {
  axios
    .post(API_URL.REUPLOAD_NEW, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

export function ReuploadExist_API(data, res) {
  axios
    .post(API_URL.REUPLOAD_EXIST, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

export function ReuploadMissing_API(data, res) {
  axios
    .post(API_URL.REUPLOAD_MISSING, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

export function VAX_DETAILS_UPDATE(data, res) {
  axios
    .post(API_URL.VAX_DETAILS_UPDATE, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//Get Countrylist API
export function GET_BVI_COUNTRYLIST(res) {
  axios
    .get(API_URL.GET_BVI_COUNTRYLIST)
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}

//Get STATElist API
export function GET_BVI_STATELIST(data, res) {
  axios
    .post(API_URL.GET_BVI_STATELIST + "?countryId=" + data.countryId, {})
    .then((response) => {
      if (response) {
        res(response.data);
      }
    })
    .catch((error) => {
      if (error.response) {
        res(error.response.data);
      }
    });
}
