import React, { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  CircularProgress,
  FormControl,
  InputLabel,
  TextField,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "../PatientInfo/patientInfo.css";
import moment from "moment";
import { useHistory } from "react-router";
import DeleteIcon from '@material-ui/icons/Delete';
import * as actions from "../../Action/index";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LiveTvSharp } from "@material-ui/icons";
// Material UI Global Styles
const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  input: {
    color: "#00AFB9",
  },
  paper: {
    position: "absolute",

    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: "20px",
  },
}));
const PatientInfo = () => {
  const history = useHistory();

  const classes = useStyles();
  const [inputList, setInputList] = React.useState([
    {
      vaccineType: "",
      vaccineDate: null,
      first_notsure: false,
      lotNumber: "",
      location: "",
      clinicName: "",
      firstdoseLoc: "",
      country: "",
      stateList: []
    },
  ]);
  const [countryList, setCountryList] = React.useState([]);

  useEffect(() => {
    console.log("{localStorage.getItem('pdf')", localStorage.getItem("pdf"));
    if (localStorage.getItem("pdf") === null) {
      history.push({
        pathname: "/retrievalcode",
      });
    }

    actions.GetVaccination_Action((res) => {
      console.log("GetVaccination_Action", res);
      setVaccineName(res.info);
    });

    // -----------------------------------------------------------------------------
    actions.GetOrg_Action((res) => {
      var deletedItem = res.info.splice(34, 1);
      let addOthers = [
        {
          organizationName: "Other",
          labId: 32,
        },
      ];
      let getres = [...addOthers, ...res.info];
      setstateOrg(getres);
    });

    //======Get CountryList======//
    actions.GET_BVI_COUNTRYLIST((res) => {
      setCountryList(res.info);
    });

  }, [inputList]);

  // ----------------------------------------------------------------------------------------------------
  const [showDiv1, setshowDiv1] = React.useState(true);
  const [showDiv2, setshowDiv2] = React.useState(false);

  const [vaccineDateError, setdoseDateError] = React.useState(true);
  const [firstdoselocError, setfirstdoselocError] = React.useState(true);
  const [firstDoseError, setfirstDoseError] = React.useState(true);
  const [vaccineError, setvaccineError] = React.useState(true);
  const [vaccinenameError, setvaccineNameError] = React.useState(true);
  const [firstdoselotnoError, setfirstdoselotnoError] = React.useState(true);
  const [vaccineTypeError, setvaccineTypeError] = React.useState(false);
  const [stateError, setstateError] = React.useState(true);
  const [countryError, setcountryError] = React.useState(true);
  const [stateOrg, setstateOrg] = React.useState([]);
  const [loader, setloader] = React.useState(false);
  const [ApiError, SetApiError] = React.useState(false);
  const [vaccineFile, setvaccineFile] = React.useState();
  const [VaccineName, setVaccineName] = React.useState([]);
  const [vaccineFilesize, setvaccineFilesize] = React.useState(false);
  const [VaccineimgShow, setVaccineimgshow] = React.useState("");
  const [Stateloader, setStateloader] = React.useState(false);
  //==== Vaccination Handler function ====//----------------------------------------------------------------------------
  const VaccineHandler = (event) => {
    const fileTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
      "image/heif",
    ];
    if (
      event.target.files.length != 0 &&
      fileTypes.includes(event.target.files[0].type)
    ) {
      if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "image/jpeg" ||
          event.target.files[0].type === "image/png" ||
          event.target.files[0].type === "image/jpg")
      ) {
        setvaccineFile(event.target.files);
        setVaccineimgshow(
          event.target.files.length != 0
            ? URL.createObjectURL(event.target.files[0])
            : ""
        );
        setvaccineTypeError(false);
        setvaccineError(true);
      } else if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "application/pdf" ||
          event.target.files[0].type === "image/heif")
      ) {
        setvaccineFile(event.target.files);
        setVaccineimgshow("");
        setvaccineTypeError(false);
        setvaccineError(true);
      }
    } else {
      setVaccineimgshow("");
      setvaccineFile();
      setvaccineTypeError(true);
    }

    const fileSize =
      event.target.files.length != 0
        ? event.target.files[0].size / 1024 / 1024
        : "";
    if (event.target.files.length != 0 && fileSize > 4) {
      setvaccineFilesize(true);
    } else {
      setvaccineFilesize(false);
    }
  };

  //========Submit Function===========//

  const EnterKeynext = (evt) => {
    if (evt.keyCode == 13) {
      Verifiysubmit();
    }
  };
  // ----------------------------------------------------------------------------------------------------

  const Verifiysubmit = (e) => {
    if (
      vaccineFile === undefined ||
      vaccineFile === "" ||
      vaccineFile.length == 0
    ) {
      setvaccineError(false);
    } else {
      let arr = inputList.map((item) => {
        return {
          clinicName:
            item.clinicName == "Other?32"
              ? item.firstdoseLoc.trim()
              : item.clinicName.split("?")[0],
          labId:
            item.clinicName == "Other?32"
              ? 32
              : parseInt(item.clinicName.split("?")[1]),
          location: item.location,
          lotNumber:
            item.first_notsure === true
              ? "I'm not sure"
              : item.lotNumber.trim(),
          vaccineDate: moment(item.vaccineDate).format("yyyy-MM-DD"),
          vaccineType: item.vaccineType,
          country: item.country,
        };
      });

      let filter_data = arr.filter(function (x) {
        return (
          x.clinicName != "" &&
          x.lotNumber != "" &&
          x.vaccineType != "" &&
          x.country != "" &&
          x.location != "" &&
          x.labId != NaN &&
          (x.vaccineDate != "Invalid date" || x.vaccineDate != null)
        );
      });
      let Checkfilter_data = arr.filter(function (x) {
        return (
          x.clinicName === "" ||
          x.lotNumber === "" ||
          x.vaccineType === "" ||
          x.country === "" ||
          x.location === "" ||
          x.labId === NaN ||
          x.vaccineDate === "Invalid date" ||
          x.vaccineDate === null
        );
      });
      console.log("equal", Checkfilter_data);
      console.log("not equal", filter_data);

      for (let i = 0; i < inputList.length; i++) {
        if (inputList[i].vaccineType === "") {
          setvaccineError(true);
          setvaccineNameError(false);
          setdoseDateError(true);
          setfirstdoselotnoError(true);
          setfirstdoselocError(true);
          setfirstDoseError(false);
        } else if (inputList[i].vaccineDate === null) {
          setvaccineError(true);
          setvaccineNameError(true);
          setdoseDateError(false);
        } else if (
          inputList[i].first_notsure === false &&
          inputList[i].lotNumber.trim() === ""
        ) {
          setvaccineError(true);
          setvaccineNameError(true);
          setdoseDateError(true);
          setfirstdoselotnoError(false);
        } else if (inputList[i].country === "") {
          setvaccineError(true);
          setvaccineNameError(true);
          setdoseDateError(true);
          setfirstdoselotnoError(true);
          setcountryError(false);
        } else if (inputList[i].location === "") {
          setvaccineError(true);
          setvaccineNameError(true);
          setdoseDateError(true);
          setfirstdoselotnoError(true);
          setcountryError(true);
          setstateError(false);
          setcountryError(true);
        } else if (inputList[i].clinicName.trim() === "") {
          setvaccineError(true);
          setvaccineNameError(true);
          setdoseDateError(true);
          setfirstdoselotnoError(true);
          setcountryError(true);
          setstateError(true);
          setfirstdoselocError(false);

        } else if (
          inputList[i].clinicName === "Other?32" &&
          inputList[i].firstdoseLoc.trim() === ""
        ) {
          console.log(
            "df",
            inputList[0].clinicName === "Other?32",
            inputList[0].firstdoseLoc === ""
          );
          setvaccineError(true);
          setvaccineNameError(true);
          setdoseDateError(true);
          setfirstdoselotnoError(true);
          setfirstdoselocError(true);
          setcountryError(true);
          setstateError(true);
          setfirstDoseError(false);
        }
      }

      if (Checkfilter_data.length === 0) {
        setloader(true);
        setvaccineError(true);
        setvaccineNameError(true);
        setdoseDateError(true);
        setfirstdoselotnoError(true);
        setfirstdoselocError(true);
        setfirstDoseError(true);
        setcountryError(true);
        setstateError(true);
        let data = {
          patientID: localStorage.getItem("patientId"),
          vaccinationInfos: filter_data,
        };
        console.log("API call >>>>>>>>>>>>>>>>>");
        console.log(data);
        const formData = new FormData();
        formData.append("formData", JSON.stringify(data));
        formData.append("cardFile", vaccineFile[0]);
        actions.MissingDose_Action(formData, (res) => {
          if (res.statusCode == 200) {
            setloader(false);
            setvaccineFile();
            setshowDiv1(false);
            setshowDiv2(true);
          }
        });
      }
      // api call
    }
  };

  // ----------------------------------------------------------------------------------------------------
  const handleInputChange = (e, index, datename) => {
    if (datename === "vaccineDate") {
      const list = [...inputList];
      list[index]["vaccineDate"] = e;
      setInputList(list);
    } else if (
      datename === "first_notsure" &&
      (e.target.checked === false || e.target.checked === true)
    ) {
      const { name, checked } = e.target;
      const list = [...inputList];
      list[index][name] = checked;
      setInputList(list);
    } else if (
      datename === "location" ||
      datename === "vaccineType"
    ) {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    } else if (datename === "lotNumber" ||
      datename === "firstdoseLoc") {
      const name = e.target.name;
      const value = e.target.value.replaceAll('"', "'");
      const list = [...inputList];
      list[index][name] = value.replaceAll("''", "'");
      setInputList(list);
    }
    else if (datename === "country") {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
      setStateloader(true);
      countryList.map((item) => {
        if (item.countryCode == value) {
          let data = {
            countryId: item.id,
          };
          actions.GET_BVI_STATELIST(data, (res) => {
            if (res.statusCode === 200) {
              const list = [...inputList];
              list[index]["location"] = "";
              let allEntries = Object.entries(res.info.states);
              let sortedEntries = allEntries.sort((a, b) =>
                a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1
              );
              list[index]["stateList"] = sortedEntries;
              setStateloader(false);
            }
          });
        }
      });
      setInputList(list);
    }
    else if (e.target.value && datename === "clinicName") {
      const { name, value } = e.target;
      const list = [...inputList];
      list[index][name] = value;
      setInputList(list);
    }
  };

  // --------------------------------------------------------------------------------------------------
  const handleAddClick = () => {
    if (
      vaccineFile === undefined ||
      vaccineFile === "" ||
      vaccineFile.length == 0
    ) {
      setvaccineError(false);
    } else {
      let arr = inputList.map((item) => {
        return {
          clinicName:
            item.clinicName == "Other?32"
              ? item.firstdoseLoc.trim()
              : item.clinicName.split("?")[0],
          labId:
            item.clinicName == "Other?32"
              ? 32
              : parseInt(item.clinicName.split("?")[1]),
          location: item.location,
          lotNumber:
            item.first_notsure === true
              ? "I'm not sure"
              : item.lotNumber.trim(),
          vaccineDate: moment(item.vaccineDate).format("MM-DD-yyyy"),
          vaccineType: item.vaccineType,
          country: item.country
        };
      });

      let filter_data = arr.filter(function (x) {
        return (
          x.clinicName != "" &&
          x.lotNumber != "" &&
          x.vaccineType != "" &&
          x.country != "" &&
          x.location != "" &&
          x.labId != NaN &&
          (x.vaccineDate != "Invalid date" || x.vaccineDate != null)
        );
      });
      let Checkfilter_data = arr.filter(function (x) {
        return (
          x.clinicName === "" ||
          x.lotNumber === "" ||
          x.vaccineType === "" ||
          x.country === "" ||
          x.location === "" ||
          x.labId === NaN ||
          x.vaccineDate === "Invalid date" ||
          x.vaccineDate === null
        );
      });
      console.log("equal", Checkfilter_data);
      console.log("not equal", filter_data);

      for (let i = 0; i < inputList.length; i++) {
        if (inputList[i].vaccineType === "") {
          setvaccineError(true);
          setvaccineNameError(false);
          setdoseDateError(true);
          setfirstdoselotnoError(true);
          setfirstdoselocError(true);
          setfirstDoseError(false);
        } else if (inputList[i].vaccineDate === null) {
          setvaccineError(true);
          setvaccineNameError(true);
          setdoseDateError(false);
        } else if (
          inputList[i].first_notsure === false &&
          inputList[i].lotNumber.trim() === ""
        ) {
          setvaccineError(true);
          setvaccineNameError(true);
          setdoseDateError(true);
          setfirstdoselotnoError(false);
        } else if (inputList[i].country === "") {
          setvaccineError(true);
          setvaccineNameError(true);
          setdoseDateError(true);
          setfirstdoselotnoError(true);
          setcountryError(false);
        } else if (inputList[i].location === "") {
          setvaccineError(true);
          setvaccineNameError(true);
          setdoseDateError(true);
          setfirstdoselotnoError(true);
          setcountryError(true);
          setstateError(false);
          setcountryError(true);
        } else if (inputList[i].clinicName.trim() === "") {
          setvaccineError(true);
          setvaccineNameError(true);
          setdoseDateError(true);
          setfirstdoselotnoError(true);
          setfirstdoselocError(false);
        } else if (
          inputList[i].clinicName === "Other?32" &&
          inputList[i].firstdoseLoc.trim() === ""
        ) {
          console.log(
            "df",
            inputList[0].clinicName === "Other?32",
            inputList[0].firstdoseLoc.trim() === ""
          );
          setvaccineError(true);
          setvaccineNameError(true);
          setdoseDateError(true);
          setfirstdoselotnoError(true);
          setfirstdoselocError(true);
          setfirstDoseError(false);
        }
      }
      if (Checkfilter_data.length === 0) {
        setvaccineError(true);
        setvaccineNameError(true);
        setdoseDateError(true);
        setfirstdoselotnoError(true);
        setfirstdoselocError(true);
        setfirstDoseError(true);
        setInputList([
          ...inputList,
          {
            vaccineType: "",
            vaccineDate: null,
            first_notsure: false,
            lotNumber: "",
            location: "",
            clinicName: "",
            firstdoseLoc: "",
            country: "",
            stateList: []
          },
        ]);
      }
    }
  };
  // --------------------------------------------------------------------------------------------------
  const Backbtn = () => {
    history.push({
      pathname: "/pdf",
    });
  };
  // --------------------------------------------------------------------------------------------------

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  return (
    <>
      <ToastContainer />

      {loader == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : showDiv1 === true ? (
        <div>
          <div className="infoHolder">
            <Grid container spacing={0} className="welcometext">
              <div item xs={12} md={12} sm={12}>
                <div className="gotquestions">
                  <div className="gotcss">
                    Report a missing dose from your record
                  </div>
                </div>
                  <div className="infoaccordionbg">
                    <div className="infoaccSummary">
                      {inputList.map((x, i) => {
                        return (
                          <Grid
                            key={i}
                            container
                            spacing={4}
                            style={{
                              padding: "0  18px",
                              paddingBottom: "-110px",
                              margin: "0px"
                            }}
                            xs={12}
                            md={12}
                            sm={12}
                            className="formTop mediachange"
                          >
                            {i == 0 ? (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  md={12}
                                  sm={12}
                                  style={{
                                    fontSize: "12px",
                                    color: "gray",
                                    fontSize: "16px",
                                  }}
                                >
                                  To confirm your vaccination status, please upload a picture of your most recent vaccination record or your SMART Health Card.
                                </Grid>
                                <Grid
                                  container
                                  spacing={4}
                                  xs={12}
                                  md={12}
                                  sm={12}
                                  className="formTop"
                                >
                                  <Grid item xs={12} md={6} sm={12}>
                                    <div className="uploaddiv">
                                      {vaccineFile ? (
                                        <div style={{ paddingLeft: "20px" }}>
                                          Click 'Next' to continue or upload a
                                          different image
                                          <br />
                                          <span style={{ fontSize: "14px" }}>
                                            Please make sure your name and date
                                            of birth are visible in the image
                                          </span>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        display: "inline-flex",
                                        flexDirection: "column",
                                        textAlign: "center",
                                        marginLeft: "15px",
                                      }}
                                    >
                                      <label
                                        for="file-upload1"
                                        class="custom-file-upload"
                                      >
                                        {vaccineFile
                                          ? vaccineFile.length > 0
                                            ? vaccineFile[0].name
                                            : "Upload updated vaccination record"
                                          : "Upload updated vaccination record"}
                                      </label>
                                      <span
                                        style={{
                                          paddingTop: "5px",
                                          fontSize: "10px",
                                          color: "gray",
                                        }}
                                      >
                                        Supports png, jpeg, jpg, pdf, heif
                                      </span>
                                      <input
                                        id="file-upload1"
                                        accept=".png, .jpeg, .jpg, .pdf , .heif"
                                        data-max-size="32154"
                                        type="file"
                                        name="file1"
                                        onChange={VaccineHandler}
                                      />
                                    </div>
                                    {vaccineError ? (
                                      <div></div>
                                    ) : (
                                      <div
                                        className="errorText"
                                        style={{ marginLeft: "17px" }}
                                      >
                                        Please upload vaccine record
                                      </div>
                                    )}
                                    {!vaccineFilesize ? (
                                      <div></div>
                                    ) : (
                                      <div
                                        className="errorText"
                                        style={{ marginTop: "20px" }}
                                      >
                                        File size should not exceed more than 4
                                        mb
                                      </div>
                                    )}
                                    {vaccineTypeError ? (
                                      <div
                                        className="errorText"
                                        style={{ marginTop: "15px" }}
                                      >
                                        Please upload image with required type
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                  </Grid>
                                  <Grid item xs={12} md={6} sm={12}>
                                    {vaccineFile &&
                                      vaccineFile.length > 0 &&
                                      vaccineFile[0].type != "application/pdf" &&
                                      vaccineFile[0].type != "image/heif" &&
                                      vaccineFile[0].type !== "" ? (
                                      <div style={{ width: "200px" }}>
                                        <img
                                          alt="Preview Image"
                                          src={
                                            vaccineFile
                                              ? vaccineFile.length > 0
                                                ? VaccineimgShow
                                                : ""
                                              : ""
                                          }
                                          style={{ width: "150px" }}
                                        ></img>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                </Grid>
                              </>
                            ) : (
                              ""
                            )}

                            <Grid
                              item
                              xs={12}
                              md={12}
                              sm={12}
                              style={{ marginTop: "7px" }}
                            >
                              <FormControl
                                className={classes.formControl}
                                style={{ width: "100%" }}
                              >
                                <InputLabel
                                  id="demo-simple-select-label"
                                  style={{
                                    paddingBottom: "0.3em",
                                    width: "100%",
                                  }}
                                >
                                  Vaccine name *
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="vaccineType"
                                  value={x.vaccineType}
                                  onChange={(e) =>
                                    handleInputChange(e, i, "vaccineType")
                                  }
                                  required
                                >
                                  {VaccineName.map((item) => (
                                    <MenuItem value={item.vaccineName}>
                                      {item.vaccineName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {vaccinenameError === false &&
                                x.vaccineType === "" ? (
                                <div
                                  className="errorText"
                                  style={{ marginTop: "20px" }}
                                >
                                  Please select vacccine name
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </Grid>
                            <Grid item xs={12} md={6} sm={12}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  style={{ width: "100%", marginTop: "-7px" }}
                                  clearable
                                  name="vaccineDate"
                                  value={x.vaccineDate}
                                  onChange={(e) =>
                                    handleInputChange(e, i, "vaccineDate")
                                  }
                                  format="MM/dd/yyyy"
                                  label="Dose date (MM/DD/YYYY)"
                                  maxDate={new Date()}
                                  required
                                  autoOk
                                />
                              </MuiPickersUtilsProvider>

                              {vaccineDateError === false &&
                                x.vaccineDate === null ? (
                                <div
                                  className="errorText"
                                  style={{ marginTop: "20px" }}
                                >
                                  Please enter the date
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={3}
                              sm={12}
                              style={{ paddingTop: "25px" }}
                            >
                              <TextField
                                id="standard-basic1"
                                placeholder="Lot # *"
                                InputProps={{
                                  className: classes.input,
                                }}
                                autoComplete="off"
                                disabled={
                                  x.first_notsure == true ? true : false
                                }
                                className={classes.textField}
                                value={
                                  x.first_notsure === false
                                    ? x.lotNumber
                                    : x.first_notsure === true
                                      ? "I'm not sure"
                                      : x.lotNumber
                                }
                                onChange={(e) =>
                                  handleInputChange(e, i, "lotNumber")
                                }
                                required
                                name="lotNumber"
                                inputProps={{ maxLength: 40 }}
                                onKeyUp={EnterKeynext}
                              />
                              {firstdoselotnoError === false &&
                                x.lotNumber.trim() === "" &&
                                x.first_notsure === false ? (
                                <div
                                  className="errorText"
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  Please enter LOT number
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </Grid>
                            <Grid item xs={12} md={3} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e) =>
                                      handleInputChange(e, i, "first_notsure")
                                    }
                                    name="first_notsure"
                                    checked={x.first_notsure}
                                  />
                                }
                                label="I'm not sure"
                                style={{ marginTop: "13px" }}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} sm={12}>
                              <FormControl
                                className="form-dropdown"
                                fullWidth
                                variant="standard"
                              >
                                <InputLabel>Country *</InputLabel>
                                <Select
                                  name="country"
                                  value={x.country}
                                  onChange={(e) =>
                                    handleInputChange(e, i, "country")
                                  }
                                  required
                                >
                                  {countryList.map((item) => (
                                    <MenuItem value={item.countryCode}>
                                      {item.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {countryError === false && x.country === "" ? (
                                <div className="errorText"
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "10px",
                                  }}>
                                  Please select the Country
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </Grid>
                            {Stateloader == true ? (
                              <div className="stateLoader">
                                <CircularProgress />
                              </div>
                            ) : (<Grid item xs={12} md={6} sm={12}>
                              <FormControl
                                className={classes.formControl}
                                style={{
                                  width: "100%",
                                }}
                              >
                                <InputLabel id="demo-simple-select-label">
                                  State/Territory *
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="location"
                                  value={x.location}
                                  onChange={(e) =>
                                    handleInputChange(e, i, "location")
                                  }
                                  required
                                >
                                  {x.stateList.map((item) => (
                                    <MenuItem
                                      value={item[1].stateCode}
                                    >
                                      {item[0]}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              {stateError === false && x.location === "" ? (
                                <div className="errorText"
                                  style={{
                                    marginTop: "20px",
                                    marginBottom: "10px",
                                  }}>
                                  Please select the State
                                </div>
                              ) : (
                                <div></div>
                              )}

                            </Grid>)}

                            <Grid item xs={12} md={6} sm={12}>
                              <FormControl
                                className={classes.formControl}
                                style={{ width: "100%" }}
                              >
                                <InputLabel
                                  id="demo-simple-select-label"
                                  style={{
                                    paddingBottom: "0.3em",
                                    width: "100%",
                                  }}
                                >
                                  Dose location *
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  required
                                  name="clinicName"
                                  value={x.clinicName}
                                  onChange={(e) =>
                                    handleInputChange(e, i, "clinicName")
                                  }
                                >
                                  {stateOrg.map((item) => (
                                    <MenuItem
                                      value={
                                        item.organizationName + "?" + item.labId
                                      }
                                    >
                                      {item.organizationName}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              {firstdoselocError === false &&
                                x.clinicName === "" ? (
                                <div
                                  className="errorText"
                                  style={{ marginTop: "20px" }}
                                >
                                  Please select dose location
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </Grid>

                            {x.clinicName === "Other?32" && (                              
                                <Grid item xs={12} md={6} sm={12}>
                                  <form autoComplete="off">
                                    <TextField
                                      name="firstdoseLoc"
                                      value={x.firstdoseLoc}
                                      id="standard-basic"
                                      label="Please specify the location *"
                                      onChange={(e) =>
                                        handleInputChange(e, i, "firstdoseLoc")
                                      }
                                    />
                                  </form>

                                  {firstDoseError === false &&
                                    x.firstdoseLoc.trim() === "" ? (
                                    <div
                                      className="errorText"
                                      style={{ marginTop: "20px" }}
                                    >
                                      Please enter the dose location
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </Grid>                              
                            )}
                             <Grid item xs={12} md={12} sm={12}>
                            {/* Remove div */}
                            <div className="removeDose">
                              {inputList.length !== 1 && (
                                <span
                                  style={{
                                    backgroundColor: "#f07167",
                                    padding: "3px 8px",
                                    borderRadius: "0.4rem",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  onClick={() => handleRemoveClick(i)}
                                >
                                  <DeleteIcon
                                    style={{ width: "20px", marginRight: "5px" }}
                                  />{" "}
                                  Remove
                                </span>
                              )}
                            </div>
                          </Grid>
                          </Grid>
                         
                        //    <Grid
                        //     item
                        //     xs={12}
                        //     md={12}
                        //     sm={12}
                        //     style={{
                        //       marginTop: "20px",
                        //       paddingTop: "30px",
                        //       paddingBottom: "20px",
                        //     }}
                        //   >

                        //     {ApiError === true ? (
                        //       <div
                        //         className="errorText"
                        //         style={{ marginTop: "15px", textAlign: "center" }}
                        //       >
                        //         Unable to send notification please check your email
                        //         and mobile number
                        //       </div>
                        //     ) : (
                        //       ""
                        //     )}
                        //   </Grid> 
                        // // </Grid>
                      );
                    })}
                  </div>
                </div>

                {/* Props */}
              </div>
            </Grid>
          </div>

          <div className="addDiv">
            <div>
              <button
                style={{ marginRight: "12px" }}
                className="addbtn"
                onClick={handleAddClick}
              >
                + Another Dose
              </button>
            </div>
            <div>
              <button
                style={{ marginLeft: "12px" }}
                className="missingbtn"
                onClick={Verifiysubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      ) : showDiv2 ? (
        <div className="infoHolder">
          <Grid container spacing={0} className="welcometext">
            <Grid item xs={12} md={12} sm={12}>
              <div className="gotquestions">
                <div className="gotcss">
                  Report a missing dose from your record
                </div>
              </div>

              <div className="infoaccordionbg">
                <div className="infoaccSummary">
                  <div className="ThankInfo">
                    <div className="Thankyou">Thank you! </div>
                    <div>Your Information has been received. </div>
                  </div>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    style={{ paddingTop: "50px" }}
                  >
                    <div className="submit">
                      <button
                        className="btn3"
                        onClick={Backbtn}
                        style={{ width: "30%", marginRight: "0px" }}
                      >
                        Back
                      </button>
                    </div>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </>
  );
};

export default PatientInfo;
