import React from "react";
import Headerlogo from "../../Assets/Polaris Logo.svg";
import Headerlogo1 from "../../Assets/logo1.png";

import "./header.css";
export default function Header() {
  return (
    <>
      <div className="header">
        <div>
          <img src={Headerlogo} className="headerlogo" />
        </div>
        <div>
          <img src={Headerlogo1} className="headerlogo1" />
        </div>
      </div>
    </>
  );
}
