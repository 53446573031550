import React from "react";
import { Grid, Typography } from "@mui/material";

const Success = ({ setshowdiv1, setshowdiv4, setcurrentStep,setDetails,setNumber ,setdialCode}) => {
  const next4 = () => {
    setshowdiv1(true);
    setshowdiv4(false);
    setcurrentStep(0);
    setDetails({
      firstName: "",
      lastName: "",
      middleName: "",
      email: "",
      dobmonth: "",
      dobdate: "",
      dobyear: "",
    });
    setNumber("");
    setdialCode("");
  };
  return (
    <div className="form_body">
      <Grid container className="mt">
        <Grid item xs={12} md={12} sm={12}>
          <Typography className="mb" variant="h6">
            Get your digital testing record
          </Typography>
          <div className="form_back">
            <div className="form_overlay">
              <Typography className="pt mx" variant="subtitle1"></Typography>
              <Grid container spacing={3} className="px py">
                <Grid item xs={12} md={12} sm={12}>
                  <div style={{ textAlign: "center" }}> Thank you! </div>
                  <div style={{ textAlign: "center" }}>
                    Your Information has been received.{" "}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sm={12}
                  style={{ paddingTop: "50px" }}
                >
                  <div className="submit">
                    <button
                      className="btn3"
                      onClick={next4}
                      style={{ width: "30%", marginRight: "0px" }}
                    >
                      Submit another request
                    </button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Success;
