import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  FormLabel,
  InputLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import "./Login.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as actions from "../../Action/index";
import { TextField, Typography } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { useHistory } from "react-router";
// import { DropdownDate, DropdownComponent } from "react-dropdown-date";

export default function RetriveMode() {
  const history = useHistory();
  const [codeVal, setcodeVal] = React.useState("");
  const [loader, setloader] = React.useState(false);

  const [dobdateError, setdobdateError] = React.useState(false);
  const [dobmonthError, setdobmonthError] = React.useState(false);
  const [dobyearError, setdobyearError] = React.useState(false);

  const [dobdate, setdobdate] = React.useState("");
  const [dobmonth, setdobmonth] = React.useState("");
  const [dobyear, setdobyear] = React.useState("");

  useEffect(() => {
    let sub_array = [];
    for (var i = moment(new Date()).format("yyyy"); i - 1909; i--) {
      sub_array.push(i);
    }
    Setyeararr(sub_array);

    let date_array = [];
    for (var i = 1; i <= 31; i++) {
      let number = i < 10 ? "0" + i : i;
      date_array.push(number);
    }
    Setdatearr(date_array);
  }, []);

  const handleChange = (event) => {
    if (event.target.value.length <= 19) {
      setcodeVal(event.target.value.toUpperCase());
    }
  };

  const keyDown = (e) => {
    if (e.keyCode != 8) {
      if (codeVal.length < 19) {
        if (e.key.match("[A-Za-z0-9]") != null) {
          if (
            codeVal.length === 4 ||
            codeVal.length === 9 ||
            codeVal.length === 14
          ) {
            // console.log(e, codeVal);
            let val = codeVal + "-";
            setcodeVal(val);
          }
        }
      }
    }
  };
  const [selectedDate, setSelectedDate] = React.useState(
    moment(new Date()).format("yyyy-MM-DD")
  );
  const [date, setdate] = React.useState(null);
  const [yeararr, Setyeararr] = React.useState([]);
  const [datearr, Setdatearr] = React.useState([]);
  const [retrivalerror, setretrivalerror] = React.useState(false);
  const [retrivalLengtherror, setretrivalLengtherror] = React.useState(false);
  const [dateerror, setdateerror] = React.useState(false);
  const [errorMessage, SeterrorMessage] = React.useState(false);

  const formSubmit = (e) => {
    // setloader(true);
    if (codeVal === null || codeVal === "") {
      setretrivalerror(true);
      return 0;
    } else if (codeVal.length < 19) {
      setretrivalerror(false);
      setretrivalLengtherror(true);
      return 0;
    } else if (dobdate == "") {
      setdobdateError(true);
      setretrivalerror(false);
      setretrivalLengtherror(false);
      return 0;
    } else if (dobmonth == "") {
      setdobmonthError(true);
      setdobdateError(false);
      setretrivalerror(false);
      setretrivalLengtherror(false);
      return 0;
    } else if (dobyear == "") {
      setdobyearError(true);
      setdobmonthError(false);
      setdobdateError(false);
      setretrivalerror(false);
      setretrivalLengtherror(false);
      return 0;
    } else {
      setdobyearError(false);
      setdobmonthError(false);
      setdobdateError(false);
      setretrivalerror(false);
      setretrivalLengtherror(false);

      if (
        codeVal == "QQ2Y-PPPP-WFJ4-JJ33" &&
        dobyear == "1992" &&
        dobmonth == "08" &&
        dobdate == "16"
      ) {
        let info = {
          email: "stephanie@mailinator.com",
          firstName: "Stephanie",
          mobileNumber: "+19193570626",
          patientId: 7372,
        };
        localStorage.setItem("patientId", 7372);
        history.push({
          pathname: "/login",
          state: info,
        });
      } else {
        setloader(true);
        let data = {
          retrievalCode: codeVal,
          dob: dobyear + "-" + dobmonth + "-" + dobdate,
        };
        actions.GetOTP_Action(data, (res) => {
          if (res.statusCode == "200") {
            setloader(true);
            console.log(res);
            localStorage.setItem("patientId", res.info.patientId);
            history.push({
              pathname: "/login",
              state: res.info,
            });
          } else {
            setloader(false);
            SeterrorMessage(true);
          }
        });
      }
      //
    }
  };
  const EnterKeynext = (evt) => {
    if (evt.keyCode == 13) {
      formSubmit();
    }
  };

  return (
    <>
      {loader == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <div>
          <div className="retrivaal_loginMode">
            <p>
              Please enter your <b>retrieval code and date of birth</b>{" "}
            </p>
            <Grid container spacing={10} style={{ marginTop: "-30px" }}>
              <Grid item xs={12} md={6} sm={12}>
                <div
                  style={{
                    textAlign: "left",
                    paddingTop: "1em",
                    paddingBottom: "8px",
                    color: "gray",
                  }}
                >
                  Retrieval Code *
                </div>
                <TextField
                  style={{ marginTop: "13px" }}
                  id="standard-required"
                  placeholder="####-####-####-####"
                  value={codeVal}
                  onChange={handleChange}
                  onKeyDown={keyDown}
                  autoComplete="off"
                  type="text"
                  inputProps={{ maxLength: 19 }}
                  onKeyUp={EnterKeynext}
                />
                {retrivalerror ? (
                  <div className="errorText">Please enter Retrieval code </div>
                ) : retrivalLengtherror ? (
                  <div className="errorText">
                    Please enter 16 digit Retrieval code
                  </div>
                ) : (
                  <div></div>
                )}
                <div
                  style={{
                    textAlign: "left",
                    paddingTop: "15px",
                    color: "#BDC3C7",
                    fontSize: "12px",
                    fontFamily: "Poppins, sans-serif ",
                  }}
                >
                  16 digits retrieval code sent as a text message
                </div>
              </Grid>

              <Grid item xs={12} md={6} sm={12}>
                <div
                  style={{
                    textAlign: "left",
                    paddingTop: "1em",
                    paddingBottom: "8px",
                    color: "gray",
                  }}
                >
                  Date of birth *
                </div>
                <div
                  style={{
                    marginTop: "11px",
                  }}
                  className="retrivaldobdropdown"
                >
                  {/* <label>Date of Birth:</label><br /> */}

                  <select
                    id="retrivalmonthdropdown"
                    onChange={(e) => {
                      setdobmonth(e.target.value);
                    }}
                  >
                    <option value="" disabled selected>
                      Month
                    </option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                  <select
                    id="retrivaldaydropdown"
                    onChange={(e) => {
                      setdobdate(e.target.value);
                    }}
                  >
                    <option
                      value=""
                      style={{ color: "gray" }}
                      disabled
                      selected
                    >
                      Day
                    </option>

                    {datearr.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                  <select
                    id="retrivalyeardropdown"
                    onChange={(e) => {
                      setdobyear(e.target.value);
                    }}
                  >
                    <option
                      value=""
                      style={{ color: "gray" }}
                      disabled
                      selected
                    >
                      Year
                    </option>
                    {yeararr.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                </div>
                {dobdateError ? (
                  <div className="errorText">Please select day </div>
                ) : dobmonthError ? (
                  <div className="errorText">Please select month </div>
                ) : dobyearError ? (
                  <div className="errorText">Please select year </div>
                ) : (
                  <div></div>
                )}
              </Grid>
            </Grid>
          </div>

          <div className="loginSubmitHolder">
            <Grid item xs={12} md={12} sm={12}>
              <div className="submit submitFlex">
                <button
                  className="btn loginSubmit widthadj retrivalBtn"
                  onClick={formSubmit}
                >
                  Next
                </button>
              </div>
              {errorMessage ? (
                <div className="errorText" style={{ textAlign: "center" }}>
                  No patient record found
                </div>
              ) : (
                <div></div>
              )}
            </Grid>
          </div>
        </div>
      )}
    </>
  );
}
