import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import lockicon from "../../Assets/lock_icon.png";
import * as actions from "../../Action/index";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";

const Loginotp = (props) => {
  console.log("props.location.state", props.location.state);
  const history = useHistory();
  const [getOTP, setOTP] = React.useState("");
  const [loader, setloader] = React.useState(false);
  const [errorOTP, seterrorOTP] = React.useState(false);
  const [errorSuccessOTP, seterrorSuccessOTP] = React.useState(false);
  const [ErrorOTPmess, setErrorOTPmess] = React.useState(false);

  const handleOTP = (e) => {
    setOTP(e.target.value);
  };
  const TestPDFURL =
    "https://staging-hummingbird-health.s3.amazonaws.com/humming-bird-health/test/staging/patient-result/7372/result-1167/patient-7372-published-test-1167.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20211108T122108Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Credential=AKIA5NXHKJDMN3YC3BUT%2F20211108%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=4069fe350e5dd8dfe026da44fde4c720d6f52b1262c2e5534e565726b28147ad";
  const SubmitOTP = (e) => {
    if (getOTP == "") {
      seterrorOTP(true);
    } else {
      if (localStorage.getItem("patientId") == "7372") {
        setloader(true);
        seterrorOTP(false);
        let data = {
          otp: getOTP,
        };
        actions.ValidateOTP_Action(data, (res) => {
          console.log(res);
          if (res.statusCode == "200") {
            setloader(false);
            localStorage.setItem("pdf", TestPDFURL);
            localStorage.setItem("wallet", res.info.walletPdfUrl);
            localStorage.setItem("applestate", res.info.applePassUrl);
            localStorage.setItem("googlestate", res.info.googlePassUrl);

            history.push({
              pathname: "/pdf",
            });
          } else {
            setloader(false);
            setErrorOTPmess(true);
            seterrorSuccessOTP(false);
          }
        });
      } else {
        setloader(true);
        seterrorOTP(false);
        let data = {
          otp: getOTP,
        };
        actions.ValidateOTP_Action(data, (res) => {
          console.log(res);
          if (res.statusCode == "200") {
            setloader(false);
            localStorage.setItem("pdf", res.info.digitalCardURL);
            localStorage.setItem("wallet", res.info.walletPdfUrl);
            localStorage.setItem("applestate", res.info.applePassUrl);
            localStorage.setItem("googlestate", res.info.googlePassUrl);

            history.push({
              pathname: "/pdf",
            });
          } else {
            setloader(false);
            setErrorOTPmess(true);
            seterrorSuccessOTP(false);
          }
        });
      }
    }
  };
  const ResendOTP = () => {
    setloader(true);
    let data = {
      email: props.location.state.email,
      mobileNumber: props.location.state.mobileNumber,
      messageCode: null,
      patientId: props.location.state.patientId,
      firstName: props.location.state.firstName,
    };
    actions.SendOTP_Action(data, (res) => {
      if (res.statusCode == "200") {
        seterrorOTP(false);
        setloader(false);
        seterrorSuccessOTP(true);
      } else {
        setloader(false);
      }
    });
  };
  const EnterKeynext = (evt) => {
    if (evt.keyCode == 13) {
      SubmitOTP();
    }
  };
  return (
    <>
      <ToastContainer />
      {loader == true ? (
        <div className="loaderAdj">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="Otp_loginMode">
            <Grid item xs={12} md={12} sm={12}>
              <img src={lockicon} className="lockicon" />
            </Grid>

            <p>Enter the verification code we just sent to your</p>
            <p>email / mobile number</p>

            <div className="otpText">
              <Grid item xs={12} md={12} sm={12}>
                <div style={{ textAlign: "center" }}>
                  <TextField
                    id="standard-basic"
                    style={{
                      borderBottom: "1px solid black",
                      width: "60%",
                      textAlign: "center",
                    }}
                    autoComplete="off"
                    value={getOTP}
                    onChange={handleOTP}
                    onKeyUp={EnterKeynext}
                  />
                  {errorOTP ? (
                    <div className="errorText">Please enter OTP</div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </Grid>
            </div>

            <div className="resend">
              <Grid item xs={12} md={12} sm={12}>
                <div onClick={ResendOTP}>RESEND</div>
                {errorSuccessOTP ? (
                  <div className="SuccessText"> OTP sent successfully</div>
                ) : (
                  <div></div>
                )}
              </Grid>
            </div>
          </div>

          <div className="loginSubmitHolder">
            <Grid item xs={12} md={12} sm={12}>
              <div className="submit submitFlex">
                <button
                  className="btn OtpBtn loginSubmit widthadj"
                  onClick={SubmitOTP}
                >
                  Next
                </button>
              </div>
              {ErrorOTPmess ? (
                <div style={{ textAlign: "center" }} className="errorText">
                  Invalid OTP
                </div>
              ) : (
                <div></div>
              )}
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default Loginotp;
