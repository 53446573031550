import React from "react";
import { Grid, Typography } from "@mui/material";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";

const SecondStepper = ({
  setshowdiv2,
  setshowdiv3,
  setcurrentStep,
  setloader,
}) => {
  //======License Image State======//
  const [drivingFile, setdrivingFile] = React.useState();
  const [drivingFilesize, setdrivingFilesize] = React.useState(false);
  const [drivingTypeError, setdrivingTypeError] = React.useState(false);
  const [drivingimgShow, setdrivingimgshow] = React.useState("");
  const [drivingError, setdrivingError] = React.useState(false);

  //======On Change Functionalities======//
  const DrivingHandler = (event) => {
    setdrivingTypeError(false);
    setdrivingError(false);
    const fileTypes = [
      "image/jpg",
      "image/jpeg",
      "image/png",
      "application/pdf",
      "image/heif",
    ];
    if (
      event.target.files.length != 0 &&
      fileTypes.includes(event.target.files[0].type)
    ) {
      if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "image/jpeg" ||
          event.target.files[0].type === "image/png" ||
          event.target.files[0].type === "image/jpg")
      ) {
        setdrivingFile(event.target.files);
        setdrivingimgshow(
          event.target.files.length != 0
            ? URL.createObjectURL(event.target.files[0])
            : ""
        );
        setdrivingError(false);
      } else if (
        event.target.files.length != 0 &&
        (event.target.files[0].type === "application/pdf" ||
          event.target.files[0].type === "image/heif")
      ) {
        setdrivingFile(event.target.files);
        setdrivingimgshow("");
        setdrivingError(false);
      }
    } else {
      setdrivingimgshow("");
      setdrivingFile();
      setdrivingError(false);
      setdrivingTypeError(true);
    }

    const fileSize =
      event.target.files.length != 0
        ? event.target.files[0].size / 1024 / 1024
        : "";
    if (event.target.files.length != 0 && fileSize > 4) {
      setdrivingError(false);
      setdrivingTypeError(false);
      setdrivingFilesize(true);
    } else {
      setdrivingFilesize(false);
    }
  };

  const next2 = (e) => {
    if (
      drivingFile === undefined ||
      drivingFile === "" ||
      drivingFile.length == 0
    ) {
      setdrivingError(true);
      setdrivingTypeError(false);
    } else if (drivingFilesize == true) {
      return 0;
    } else {
      console.log(">>>>>>> API call >>>>>>>>>>");
      setloader(true);
      const formData = new FormData();
      formData.append("licenseFile", drivingFile[0]);
      console.log("formData", formData);

      setshowdiv2(false);
      setshowdiv3(true);
      setcurrentStep(2);
      window.scrollTo(0, 0);
      setloader(false);

      //===================//
    }
  };

  return (
    <>
      <div className="form_body">
        <Grid container className="mt">
          <Grid item xs={12} md={12} sm={12}>
            <Typography className="mb" variant="h6">
              Get your digital testing record
            </Typography>
            <div className="form_back">
              <div className="form_overlay">
                <Typography className="pt mx" variant="subtitle1">
                  Verification
                </Typography>
                <Typography className="mx my gray-text" variant="subtitle1">
                  To keep your data secure and confirm your identity, please
                  upload a picture of your driver's license or other government
                  issued ID.
                </Typography>
                <Grid container spacing={3} className="px py">
                  <Grid item xs={12} md={6} sm={12}>
                    <div className="license-card">
                      {drivingFile ? (
                        <div>
                          Click 'Next' to continue or upload a new image
                          <br />
                          <span style={{ fontSize: "14px" }}>
                            Please make sure your name and date of birth are
                            visible in the image
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="d-inline-flex">
                      <label
                        htmlFor="file-upload"
                        className="custom-file-upload"
                      >
                        {drivingFile ? (
                          drivingFile.length > 0 ? (
                            drivingFile[0].name
                          ) : (
                            <span className="d-flex">
                              <InsertPhotoIcon
                                style={{ width: "20px", marginRight: "5px" }}
                              />
                              Upload Photo ID
                            </span>
                          )
                        ) : (
                          <span className="d-flex">
                            <InsertPhotoIcon
                              style={{ width: "20px", marginRight: "5px" }}
                            />
                            Upload Photo ID
                          </span>
                        )}
                      </label>

                      <span className="img-label">
                        Supports png, jpeg, jpg, pdf, heif
                      </span>

                      <input
                        id="file-upload"
                        data-max-size="32154"
                        accept=".png, .jpeg, .jpg, .pdf, .heif"
                        type="file"
                        name="vaccinationFile"
                        onChange={DrivingHandler}
                      />
                    </div>
                    {drivingError ? (
                      <div className="errorText">Please upload photo ID</div>
                    ) : (
                      <div></div>
                    )}
                    {drivingFilesize ? (
                      <div className="errorText">
                        File size should not exceed more than 4 mb
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {drivingTypeError ? (
                      <div className="errorText">
                        Please upload image with required type
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} sm={12}>
                    {drivingFile &&
                    drivingFile.length > 0 &&
                    drivingFile[0].type != "application/pdf" &&
                    drivingFile[0].type != "image/heif" &&
                    drivingFile[0].type !== "" ? (
                      <div className="testing-preview-img">
                        <img
                          alt="Preview-license"
                          src={
                            drivingFile
                              ? drivingFile.length > 0
                                ? drivingimgShow
                                : ""
                              : ""
                          }
                        ></img>
                      </div>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>

                <Grid container spacing={3} className="px py">
                  <Grid item xs={12} md={12} sm={12}>
                    <div className="d-flex">
                      <div>
                        <button className="verifier_btn" onClick={next2}>
                          Next
                        </button>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default SecondStepper;
